import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { brand } from "../../util/constants";

export default class Home extends Component {
  federatedSignInUpdateUser = async () => {
    try {
      const newUser = await Auth.federatedSignIn({ customProvider: "AzureAD" });
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    return (
      <div className="my">
        <div className="sign-in-box">
          <div className="box">
            <div className="title">
              <span className="block"></span>
              <h1>
                {brand.NAME}
                <span></span>
              </h1>
            </div>

            <div className="role">
              <div className="block"></div>
              <h2
                onClick={this.federatedSignInUpdateUser}
                className="hover-underline-animation"
              >
                Sign In
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
