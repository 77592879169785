import React, { Component } from "react";
import WabaDashboard from "./WabaDashboard";

export default class Dashboard extends Component {
  render() {
    return (
      <div className="container-fluid ">
        <WabaDashboard location={this.props.location} />
      </div>
    );
  }
}
