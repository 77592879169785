import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Table,
  Tooltip,
  Modal,
  Col,
  Row,
  Popconfirm,
  message,
} from "antd";
import { Navigate } from "react-router-dom";
import {
  CheckSquareTwoTone,
  DeleteTwoTone,
  DownloadOutlined,
  EditTwoTone,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { getAllEvents } from "../../../actions/eventActions";
import Pusher from "pusher-js";
import Query from "../query/query";
import BreadCrumbs from "../../BreadCrumbs";
import axios from "axios";
import { saveAs } from "file-saver";
import { Auth } from "aws-amplify";
class Broadcast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateToMessage: false,
      navigateToBroadcast: false,
      messageDetail: "",
      eventId: "",
      loadingTable: true,
      disabled: true,
      visible: false,
      isEnable: true,
      optionsList: [],
      broadcasttype: "Normal",
    };
  }

  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    var pusher = new Pusher("20cf801b69f19fcea0a6", {
      cluster: "ap2",
    });
    const channelName = "whatsapp_employee_connect";
    var channel = pusher.subscribe(channelName);
    channel.bind("my_event", async function (data) {
      await this.props?.getAllEvents(accessToken); //Get All Events
    });
    await this.props?.getAllEvents(accessToken);
    this.optionListRender(accessToken); //Option Modal Table
    this.setState({
      loadingTable: false,
    });
  }

  handleBroadcastType = (event) => {
    this.setState({ broadcasttype: event });
  };

  // Option Modal Table
  optionListRender = async (accessToken) => {
    let options = await axios.get(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/presignedurl/event_options?id=${this.props.location.state._id}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    this.addFlagInItem(options.data);
    this.setState({
      optionsList: options.data,
    });
  };

  //Adding an flag item
  addFlagInItem = (list) => {
    list.forEach((value) => {
      value["isEnable"] = false;
    });
    this.setState({ optionsList: list });
  };

  //Navigate to Message
  navigateToMessage = (text) => {
    this.setState({ navigateToMessage: true, messageDetail: text });
  };

  //Navigate to View Broadcast
  navigateToViewBroadcast = (event) => {
    this.setState({ eventId: event._id, navigateToBroadcast: true });
  };

  //Show Modal
  showModal = async () => {
    this.setState({
      visible: true,
    });
  };

  //Handle Modal Cancel
  handleModalCancel = () => {
    this.setState({ visible: false });
  };

  //Edit Option
  callOnEdit = (data) => {
    const row = [...this.state.optionsList];
    const currentIndex = row.indexOf(row.find((e) => e._id === data._id));
    const b = row[currentIndex]["isEnable"];
    row[currentIndex]["isEnable"] = !b;
    this.setState({ optionsList: row });
  };

  //Delete Option
  callOnDelete = async (id) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let resp = await axios
      .post(
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/event`,
        { optionId: id },
        { headers: { Authorization: "Bearer " + accessToken } }
      )
      .then((resp) => {
        this.optionListRender();
      })
      .catch((error) => {
        message.error(`Something went wrong`);
      });
  };

  //Handle Change
  handleChange = (data) => (e) => {
    const { name, value } = e.target;
    const row = [...this.state.optionsList];
    const currentIndex = row.indexOf(row.find((e) => e._id === data._id));
    row[currentIndex][name] = value;
    this.setState({ optionsList: row });
  };

  //Download Image
  downloadImage = async (key) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let response = await axios.get(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/presignedurl?key=${key}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    saveAs(response.data.url);
  };

  //Save Edited Option
  callSaveUpdateOption = async (data) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    const update = {
      optionId: data._id,
      description: data.titledescription,
      reply: data.reply,
      eventId: data.eventId,
    };
    let resp = await axios
      .post(
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/event`,
        update,
        { headers: { Authorization: "Bearer " + accessToken } }
      )
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          message.success(`Option Updated successfully`);
        }
        this.optionListRender();
      })
      .catch((error) => {
        message.error(`Something went wrong`);
      });
  };

  render() {
    const { visible } = this.state;
    const userCol = [
      {
        title: "Message",
        align: "left",
        width: "25%",
        render: (text) => (
          <a
            className=""
            type="primary"
            onClick={() => this.navigateToMessage(text)}
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {text.message === "" ? (
              <Tooltip title={text.message}>
                <div className="text-cover">{text.fileName}</div>
              </Tooltip>
            ) : (
              <Tooltip title={text.message}>
                <div className="text-cover"> {text.message}</div>
              </Tooltip>
            )}
          </a>
        ),
      },
      {
        title: "Attachment",
        dataIndex: "media",
        align: "center",
        render: (text) =>
          text !== "" ? (
            <p className="my-para">Yes</p>
          ) : (
            <p className="my-para">No</p>
          ),
      },
      {
        title: "No. of Recipients",
        dataIndex: "",
        align: "center",
        render: (text) => (
          <p className="my-para">{text.allrecipients.length}</p>
        ),
      },
      {
        title: "Type",

        align: "center",
        render: (text) => <>{text.type}</>,
      },
      {
        title: "Queries",
        dataIndex: "",
        align: "center",
        render: (text) => (
          <Query
            broadcastId={text._id}
            queryIsExist={text.query.length > 0}
            queryLength={
              text.query.filter((queries) => queries.status === "pending")
                .length
            }
          />
        ),
        sorter: (a, b) =>
          a.query.filter((queries) => queries.status === "pending").length -
          b.query.filter((queries) => queries.status === "pending").length,
        defaultSortOrder: "descend",
        sortDirections: ["descend"],
      },
    ];
    const modelcol = [
      {
        title: "Description",
        align: "left",
        width: "15%",
        render: (data) => (
          <>
            <input
              style={{ border: "none", background: "none" }}
              name="titledescription"
              placeholder="Description"
              defaultValue={data.titledescription}
              value={this.state.titledescription}
              disabled={data.isEnable ? "" : "disabled"}
              onChange={this.handleChange(data)}
            />
          </>
        ),
      },
      {
        title: "Reply",
        align: "left",
        width: "25%",
        render: (data) => (
          <>
            <input
              style={{ border: "none", background: "none" }}
              name="reply"
              placeholder="Reply"
              defaultValue={data.reply}
              value={this.state.reply}
              disabled={data.isEnable ? "" : "disabled"}
              onChange={this.handleChange(data)}
            />
          </>
        ),
      },
      {
        title: "Attachment",
        dataIndex: "attachment",
        align: "left",
        width: "25%",
      },
      {
        title: "Preview File",
        dataIndex: "",
        align: "center",
        width: "25%",
        render: (text) => (
          <>
            {text.attachment !== null ? (
              <button
                type="button"
                className="rounded btn-success"
                onClick={() => this.downloadImage(text.attachment)}
              >
                <DownloadOutlined />
              </button>
            ) : (
              <div>No Attachment</div>
            )}
          </>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        width: "10%",
        render: (_id, data) => (
          <>
            <div>
              {data.isEnable === true && (
                <Tooltip title="Update Option">
                  <Popconfirm
                    placement="left"
                    title="Are you sure, you want to Update the Option?"
                    onConfirm={() => this.callSaveUpdateOption(data)}
                    onCancel={() => this.optionListRender()}
                    icon={
                      <QuestionCircleOutlined
                        style={{
                          color: "red",
                        }}
                      />
                    }
                  >
                    <CheckSquareTwoTone
                      style={{ color: "#5046e5" }}
                      className="icon-class"
                    />
                  </Popconfirm>
                </Tooltip>
              )}
              {data.isEnable === false && (
                <Tooltip title="Update Description or Reply">
                  <EditTwoTone
                    style={{ color: "#5046e5" }}
                    className="icon-class"
                    onClick={() => {
                      this.callOnEdit(data);
                    }}
                  />
                </Tooltip>
              )}
              &nbsp;&nbsp;&nbsp;
              <Tooltip title="Remove Option">
                <Popconfirm
                  placement="left"
                  title="Are you sure, you want to delete the Option?"
                  onConfirm={() => this.callOnDelete(data._id)}
                  onCancel={() => this.optionListRender()}
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                >
                  <DeleteTwoTone
                    twoToneColor="#d91d0f"
                    style={{
                      fontSize: "18px",
                    }}
                    size="small"
                    shape="circle"
                  />
                </Popconfirm>
              </Tooltip>
            </div>
          </>
        ),
      },
    ];

    return (
      <div className="container dashboard statistics-card">
        {this.state.navigateToMessage ? (
          <Navigate
            to="/groups/customgroupbroadcast/viewbroadcastMessage"
            state={this.state.messageDetail}
          />
        ) : (
          ""
        )}
        {this.state.navigateToBroadcast ? (
          this.state.broadcasttype === "Normal" ? (
            <Navigate
              to="/events/viewbroadcast/broadcast"
              state={this.state.eventId}
            />
          ) : (
            <Navigate to="/scheduleBroadcast" />
          )
        ) : (
          ""
        )}
        <div className="row " data-inline="true">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={18} xl={18}>
              <div style={{ margin: "-9px 0px 0px 4px" }}>
                <h1 className="page-title ">
                  {this.props.location.state.name}
                </h1>
                <BreadCrumbs location={this.props.location} />
              </div>
            </Col>

            <Col xs={7} sm={7} md={7} lg={3} xl={3}>
              <Button
                className="close-modal float-end"
                type="primary"
                onClick={this.showModal}
              >
                Options
              </Button>
            </Col>
            <Col xs={7} sm={7} md={7} lg={3} xl={3}>
              <Button
                className="add-group"
                type="primary"
                style={{}}
                onClick={() =>
                  this.navigateToViewBroadcast(this.props.location.state)
                }
              >
                New BroadCast
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mt-4">
          <div className="card">
            <div className="card-body p-5">
              <Table
                columns={userCol}
                dataSource={
                  this.props.events.filter(
                    (event) => event._id === this.props.location.state._id
                  ).length
                    ? this.props.events.filter(
                        (event) => event._id === this.props.location.state._id
                      )[0].broadcastMessages
                    : null
                }
                loading={this.state.loadingTable}
                rowKey="_id"
                align="left"
              />
            </div>
          </div>
        </div>
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="Options"
          style={{ top: 70 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          width={1000}
        >
          <Table columns={modelcol} dataSource={this.state.optionsList} />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  events: state.events.events,
});
export default connect(mapStateToProps, {
  getAllEvents,
})(Broadcast);
