import React, { Component } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Row, Col, Button, Tooltip, Select } from "antd";
import { CRow } from "@coreui/react";
import { connect } from "react-redux";
import { getAllEvents } from "../../../actions/eventActions";
import { Pie } from "@ant-design/charts";
import { CSVLink } from "react-csv";
import { Auth } from "aws-amplify";
const { Option } = Select;
var reportData = [];
let eventName;
class BroadcastPieChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiData: null,
      selectedBroadcast: null,
      sent: 0,
      broadcastType: null,
      broadcastName: null,
      campaignSelected: true,
      failed: 0,
      broadcastsInCampaing: null,
      delivered: 0,
      totalRecipients: 0,
      message: "",
    };
  }
  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    //Fetching Broadasts Pie Chart Data
    fetch(
      "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/analytics",
      { headers: { Authorization: "Bearer " + accessToken } }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ apiData: data });
      });
  }
  //Select Event
  changeBroadcastCampaige = (value) => {
    if (value !== undefined) {
      var event = this.props.events.filter((event) => event._id === value);
      eventName = event[0].name;
      this.setState({
        broadcastType: value,
        broadcastName: null,
        totalRecipients: 0,
        sent: 0,
        failed: 0,
        broadcastsInCampaing: event[0].broadcastMessages,
        campaignSelected: false,
      });
    } else {
      this.setState({
        broadcastType: null,
        totalRecipients: 0,
        sent: 0,
        failed: 0,
        broadcastName: null,
        selectedBroadcast: null,
        broadcastsInCampaing: null,
        campaignSelected: true,
      });
    }
  };

  //Select Broadcast
  broadcastChange = (value) => {
    if (value === undefined) {
      this.setState({
        broadcastName: null,
        totalRecipients: 0,
        sent: 0,
        failed: 0,
      });
    } else {
      this.setState({
        broadcastName: value,
      });
      var broadcast = this.state.broadcastsInCampaing.filter(
        (broadcast) => broadcast._id === value
      );
      let totalSent = 0;
      let totalFailed = 0;

      if (broadcast[0].allrecipients.length) {
        broadcast[0].allrecipients.map((recipient) => {
          if (recipient.status === "sent") {
            totalSent = totalSent + 1;
          } else {
            totalFailed = totalFailed + 1;
          }
        });
        reportData = [
          {
            event: eventName,
            broadcast: broadcast[0].message,
            totalSentMessages: totalSent,
            totalFailedMessages: totalFailed,
          },
        ];
        this.setState({
          totalRecipients: broadcast[0].allrecipients.length,
          sent: totalSent,
          failed: totalFailed,
        });
      } else {
        this.setState({ totalRecipients: 0, sent: 0, failed: 0 });
      }
    }
  };

  render() {
    const data = [
      {
        type: "Message Sent",
        value: this.state.sent,
      },
      {
        type: "Message Failed",
        value: this.state.failed,
      },
    ];
    const headers = [
      { label: "Event", key: "event" },
      { label: "Broadcast", key: "broadcast" },
      { label: "totalSentMessages", key: "totalSentMessages" },
      { label: "totalFailedMessages", key: "totalFailedMessages" },
    ];
    const config = {
      appendPadding: 10,
      data,
      angleField: "value",
      colorField: "type",
      radius: 0.6,
      color: ({ type }) => {
        if (type === "Message Sent") {
          return "#5678e8";
        }
        return "#e36056";
      },
      label: {
        type: "inner",
        offset: "-30%",
        style: {
          fontSize: 0,
          textAlign: "center",
        },
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    };
    return (
      <div>
        <div className="card-body">
          <div className="d-flex justify-content-center">
            <h5 className="text-secondary  mb-3">Broadcasts</h5>
          </div>
          <CRow>
            <CSVLink
              data={reportData}
              headers={headers}
              className="float-end btn-dwn"
              filename={"event-report.csv"}
            >
              {this.state.broadcastName === null ? (
                <div className="float-end dwnld-btn">
                  <Button
                    size="large"
                    type="text"
                    disabled={this.state.broadcastName === null}
                  >
                    <DownloadOutlined />
                  </Button>
                </div>
              ) : (
                <Tooltip title="Export Report to csv">
                  <div className="float-end dwnld-btn">
                    <Button size="large" type="text">
                      <DownloadOutlined />
                    </Button>
                  </div>
                </Tooltip>
              )}
            </CSVLink>
          </CRow>
          <Row gutter={[24, 8]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                <div
                  style={{
                    border: "none",
                    height: "90px",
                  }}
                >
                  <div className="card-body">
                    <div className="select_event">
                      <span className="count ">
                        <div className="count-cards-select">
                          <Select
                            style={{ width: "100%" }}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                ?.toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                            name="broadcastType"
                            placeholder="Select Event"
                            id="broadcastType"
                            defaultValue={this.props?.events[0]._id}
                            onChange={this.changeBroadcastCampaige}
                            value={this.state.broadcastType}
                          >
                            {this.props.events.map((item) => {
                              return (
                                <Option value={item._id}>{item.name}</Option>
                              );
                            })}
                          </Select>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  border: "none",
                  height: "90px",
                }}
              >
                <div className="card-body">
                  <div className="select_event">
                    <span className="count">
                      <div className="count-cards-select">
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          allowClear
                          disabled={this.state.campaignSelected}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              ?.toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          name="broadcastName"
                          placeholder="Select Broadcast"
                          id="broadcastName"
                          value={this.state.broadcastName}
                          onChange={this.broadcastChange}
                        >
                          {this.state.broadcastsInCampaing?.map((item) => {
                            return (
                              <Option value={item._id}>{item.message}</Option>
                            );
                          })}
                        </Select>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>{" "}
          <div style={{ margin: "-50px 0 0 0" }}>
            <Pie {...config} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  dashboardData: state.users.dashboardData,
  events: state.events.events,
});
export default connect(mapStateToProps, {
  getAllEvents,
})(BroadcastPieChart);
