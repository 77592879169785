import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
  Space,
  Tooltip,
} from "antd";

import { Typography } from "antd";

import {
  MinusCircleTwoTone,
  PlusOutlined,
  SaveTwoTone,
} from "@ant-design/icons";
import { message } from "antd";
import { createEvent, getAllEvents } from "../../../actions/eventActions";
import { Auth } from "aws-amplify";
import dayjs from "dayjs";
import PreviewUploadedImage from "../broadcast/PreviewUploadedImage";
const { Text } = Typography;
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class CreateEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      myname: "",
      name: "",
      date: null,
      createdBy: "",
      errors: {},
      eventDate: "",
      optionList: [],
      disabled: false,
      fileName: "",
      fileList: [],
      setFile: null,
      fileType: "",
      index: "",
      indexNumber: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onOptionListChange = this.onOptionListChange.bind(this);
  }

  //Show Modal
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  //Before Upload
  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isvideo = file.type === "video/mp4";
    const ispdf = file.type === "application/pdf";
    const isLt2M = file.size / 1024 / 1024 < 5;
    const isLt16M = file.size / 1024 / 1024 < 16;
    const isLt10 = file.size / 1024 / 1024 < 10;

    if (isJpgOrPng) {
      if (!isLt2M) {
        message.error("Image must smaller than 5MB!");
      }
    } else if (isvideo) {
      if (!isLt16M) {
        message.error("Video must smaller than 16MB!");
      }
    } else if (ispdf) {
      if (!isLt10) {
        message.error("Video must smaller than 16MB!");
      }
    } else {
      message.error("You can only upload JPG/PNG/MP4 file!");
    }

    return isLt2M;
  };

  //Cancel Modal
  handleModalCancel = () => {
    this.setState({
      visible: false,
      date: null,
      indexNumber: {},
      index: "",
      optionList: [],
      setFile: null,
      fileType: "",
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
      message.error(this.props.errors.message);
    }
  }

  //on Change Event Name
  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  //Date Change
  onDateChange = (selectedDate) => {
    if (!!selectedDate) {
      this.setState({ date: selectedDate });
    }
  };

  //On Option List Change
  onOptionListChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //On file Change
  onFileChange = async (file) => {
    let resp = await this.beforeUpload(file.target.files[0]);
    if (resp) {
      this.setState({ fileName: file.target.files[0].name });
      file.target.files[0].preview = await getBase64(file.target.files[0]);
      if (file.target.files[0].preview.length < 6) {
        message.error(
          "Please choose file again your file is not uploaded correctly"
        );
        return;
      }
      let newFileObj = [];
      newFileObj.push(file.target.files[0]);
      this.setState({
        fileList: newFileObj,
        setFile: URL.createObjectURL(file.target.files[0]),
        fileType: file.target.files[0].type,
      });
    }
  };

  //On remove Event Option
  onRemove(e) {
    const reducedArr = [...this.state.optionList];
    reducedArr.splice(e, 1);
    this.setState({ optionList: reducedArr });
    let tempArray = this.state.indexNumber;
    tempArray[e] = false;
    this.setState({ indexNumber: tempArray });
  }

  //Capitalize th string to upper case
  capitalize = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  };

  //On Saving Option
  onSave = async (index) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    if (this.state.description === undefined || this.state.description === "") {
      message.error("Description cannot be Empty!!!");
      return;
    } else {
      if (
        (this.state.reply === undefined || this.state.reply === "") &&
        (this.state.fileName === undefined || this.state.fileName === "")
      ) {
        message.error("Reply or Filename cannot be empty ");
      } else {
        var file_name = this.state.fileName;
        let extention = file_name.split(".").pop();
        var fileType;
        if (
          extention === "jpg" ||
          extention === "png" ||
          extention === "jpeg" ||
          extention === "PNG"
        ) {
          fileType = "image";
        } else {
          if (extention === "pdf") {
            fileType = "document";
          } else {
            if (extention === "mp4") {
              fileType = "video";
            }
          }
        }

        if (this.state.fileList.length !== 0) {
          let finalUrl = "";
          let url = this.state.fileList[0].preview.split("base64,");
          finalUrl = finalUrl.concat(url[1]);
          let requestObjForUploadUrl = {
            eventId: this.props.eventId,
            fileName: this.state.fileList[0].name,
            mimetype: this.state.fileList[0].type,
          };
          let resp = await axios.post(
            `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/presignedurl/event_options`,
            requestObjForUploadUrl,
            { headers: { Authorization: "Bearer " + accessToken } }
          );
          if (resp.data.statusCode === 200) {
            const uploadResponse = await axios.put(
              resp.data.body.presignedPUTURL,
              this.state.fileList[0]
            );
            if (uploadResponse.status === 200) {
              var eventObj = {
                option: this.state.index,
                titledescription: this.state.description,
                reply: this.state.reply,
                attachment: resp.data.body.pathName,
                fileType: fileType,
                fileName: this.state.fileName,
                mimeType: this.state.fileList[0].type,
                file: finalUrl,
              };
            }
          }
        } else {
          eventObj = {
            option: this.state.index,
            titledescription: this.state.description,
            reply: this.state.reply,
            attachment: null,
            fileType: fileType,
          };
        }

        this.setState((prevState) => ({
          optionList: [...prevState.optionList, eventObj],
        }));
        let tempArray = this.state.indexNumber;
        tempArray[index] = true;
        this.setState({ indexNumber: tempArray });
      }
    }
    this.setState({
      reply: "",
      description: "",
      fileList: [],
      fileType: "",
      setFile: null,
    });
  };

  //on Submit Form
  async onSubmit(event) {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false, date: null });
    }, 1000);
    let username = "";
    const user = await Auth.currentAuthenticatedUser();
    if (user && user.username) {
      username = user.username.includes("azuread_")
        ? user.username.split("azuread_")[1]
        : user.username;
    }
    var email = user.username;
    email = email.split("_");
    email = email[1].split("@");
    email = email[0].split(".");

    let name = `${this.capitalize(email[0])} ${this.capitalize(email[1])}`;
    this.setState({ myname: name });
    let eventObj = {
      name: this.state.name,
      eventDate: this.state.date,
      createdBy: name,
      options: this.state.optionList,
    };
    this.props.createEvent(eventObj, accessToken);
    setTimeout(() => {}, 1000);
    setTimeout(() => {
      this.props.createEventCallback();
    }, 2500);
  }

  render() {
    const { visible, loading } = this.state;
    const disabledDate = (current) => {
      return current && current < dayjs().endOf("day").subtract(1, "day");
    };
    return (
      <div className="add-button">
        <Button
          className="btn-admin btn-subCategory event-button float-end"
          type="primary"
          style={{}}
          onClick={this.showModal}
        >
          New Event
        </Button>
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="New Event"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          width={900}
        >
          <Form
            name="add-Group"
            className="add-Group"
            onFinish={this.onSubmit}
            initialValues={{
              remember: true,
            }}
          >
            <Row>
              <Col>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Event Name!",
                    },
                  ]}
                >
                  <Input
                    className="event_input"
                    placeholder="Event Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                  />
                </Form.Item>
              </Col>
              &nbsp; &nbsp;
              <Col>
                <Form.Item
                  name="date"
                  rules={[
                    {
                      required: this.state.date == null ? true : false,
                      message: "Please select a date",
                    },
                  ]}
                >
                  <Space direction="vertical">
                    <DatePicker
                      className="event_picker"
                      disabledDate={disabledDate}
                      name="date"
                      value={this.state.date}
                      onChange={this.onDateChange}
                    />
                  </Space>
                </Form.Item>
              </Col>
            </Row>

            <Form.List name="sights">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Row>
                      <Space key={field.key} align="baseline">
                        <Col>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.area !== curValues.area ||
                              prevValues.sights !== curValues.sights
                            }
                          >
                            {() => (
                              <Form.Item
                                {...field}
                                name={[field.name, "description"]}
                              >
                                <Input
                                  disabled={
                                    this.state.indexNumber[index] === true
                                      ? true
                                      : false
                                  }
                                  style={{ margin: "0px 49px 0 0" }}
                                  placeholder="Description"
                                  name="description"
                                  value={this.state.description}
                                  onChange={this.onOptionListChange}
                                />
                              </Form.Item>
                            )}
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item {...field} name={[field.name, "reply"]}>
                            <Input
                              placeholder="Reply"
                              name="reply"
                              disabled={
                                this.state.indexNumber[index] === true
                                  ? true
                                  : false
                              }
                              value={this.state.reply}
                              onChange={this.onOptionListChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col>
                          <Form.Item {...field} name={[field.name, "file"]}>
                            <Input
                              type="file"
                              disabled={
                                this.state.indexNumber[index] === true
                                  ? true
                                  : false
                              }
                              onChange={this.onFileChange}
                            />
                            {this.state.fileName !== "" && (
                              <div>
                                <div style={{ margin: "-34px 4px -3px auto" }}>
                                  {this.state.fileType === "video/mp4" && (
                                    <div className="container-fluid">
                                      <video width="100" height="100" controls>
                                        <source
                                          src={this.state.setFile}
                                          type="video/mp4"
                                        />
                                      </video>
                                    </div>
                                  )}
                                  {this.state.fileType !== "video/mp4" && (
                                    <Button
                                      className="float-end "
                                      style={{ border: "none" }}
                                      disabled={
                                        this.state.indexNumber[index] === true
                                          ? true
                                          : false
                                      }
                                    >
                                      <PreviewUploadedImage
                                        file={this.state.setFile}
                                        fileType={this.state.fileType}
                                      />
                                    </Button>
                                  )}
                                </div>
                              </div>
                            )}
                          </Form.Item>
                        </Col>

                        <Col>
                          <MinusCircleTwoTone
                            twoToneColor="red"
                            style={{ fontSize: "18px" }}
                            onClick={() => {
                              remove(field.name);
                              this.onRemove(index);
                            }}
                          />

                          <Tooltip
                            title={
                              this.state.indexNumber[index] === true
                                ? "Saved!!"
                                : ""
                            }
                          >
                            <Button
                              style={{ border: "none" }}
                              disabled={
                                this.state.indexNumber[index] === true
                                  ? true
                                  : false
                              }
                            >
                              <SaveTwoTone
                                twoToneColor="#5046e5"
                                style={{ fontSize: "18px" }}
                                onClick={() => this.onSave(index)}
                              />
                            </Button>
                          </Tooltip>
                          {this.state.indexNumber[index] === true && (
                            <Text type="success">Saved!!</Text>
                          )}
                        </Col>
                      </Space>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Option
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item className="float-end">
              <Button
                className="close-modal me-3"
                onClick={this.handleModalCancel}
              >
                Close
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="ok-modal"
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default connect(null, { createEvent, getAllEvents })(CreateEvent);
