import React, { Component } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import {
  LayoutFilled,
  UserAddOutlined,
  TeamOutlined,
  CalendarOutlined,
  ScheduleOutlined,
  ContainerOutlined,
  ProfileOutlined,
  ExceptionOutlined,
  WechatFilled,
  WhatsAppOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class SiderMenu extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div
        className={
          this.props.collapsed ? "sider-menu" : "sider-menu menu-position"
        }
      >
        <div className="logo-div">
          <div className="brand-name">
            <div className="brand-logo">
              <div>
                <img className="ms-5" src={""} />
              </div>
              <span class="app-name" mode="full">
                <div style={{ margin: "18px 0px 19px 25px" }}>
                  Employee Connect
                </div>
              </span>
            </div>
          </div>
        </div>

        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[window.location.pathname]}
        >
          <Menu.Item
            key="/dashboard"
            icon={<LayoutFilled />}
            style={{ fontSize: "1.5rem" }}
          >
            <NavLink className="sider-links mt-3" to="/dashboard">
              Dashboard
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/analytics"
            icon={<ContainerOutlined />}
            style={{ fontSize: "1.5rem" }}
          >
            <NavLink className="sider-links" to="/analytics">
              Analytics
            </NavLink>
          </Menu.Item>
          <p class="menu-subhead" mode="full">
            OPERATIONS
          </p>
          <Menu.Item
            key="/events"
            icon={<CalendarOutlined />}
            style={{ fontSize: "1.5rem" }}
            title="Events"
          >
            <NavLink className="sider-links " to="/events">
              Events
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/groups"
            icon={<TeamOutlined />}
            title="Group Managment"
            style={{ fontSize: "1.5rem" }}
          >
            <NavLink className="sider-links " to="/groups">
              Group Management
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/scheduleBroadcast"
            icon={<ScheduleOutlined />}
            style={{ fontSize: "1.5rem" }}
            title="Schedule Events"
          >
            <NavLink className="sider-links " to="/scheduledBroadcast">
              Schedule Broadcasts
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key="/feedback"
            icon={<ProfileOutlined />}
            style={{ fontSize: "1.5rem" }}
            title="Feedbacks"
          >
            <NavLink className="sider-links " to="/feedback">
              Feedbacks
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key="/query"
            icon={<ExceptionOutlined />}
            style={{ fontSize: "1.5rem" }}
            title="Queries"
          >
            <NavLink className="sider-links " to="/query">
              Queries
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/conversation"
            icon={<WhatsAppOutlined />}
            style={{ fontSize: "1.5rem" }}
            title="Conversation"
          >
            <NavLink className="sider-links " to="/conversation">
              Conversation
            </NavLink>
          </Menu.Item>

          {/* <Menu.Item
            key="/register"
            icon={<UserAddOutlined />}
            style={{ fontSize: "1.5rem" }}
            title="Register"
          >
            <NavLink className="sider-links " to="/register">
              Register
            </NavLink>
          </Menu.Item> */}
        </Menu>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  location: PropTypes.object.isRequired,
});
export default connect(mapStateToProps, null)(SiderMenu);
