import React, { Component } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Row, Col, Button, Tooltip, Select, Tag } from "antd";
import { CRow } from "@coreui/react";
import { connect } from "react-redux";
import { getAllEvents } from "../../../actions/eventActions";
import { Pie } from "@ant-design/charts";
import { CSVLink } from "react-csv";
import { Auth } from "aws-amplify";
const { Option } = Select;

var reportData = [];
var selectedEvent = "";
class EventPieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: null,
      sent: 0,
      disable: true,
      failed: 0,
      delivered: 0,
      totalRecipients: 0,
      totalBroadcastInCampaing: 0,
      totalBroadcast: 0,
      message: "",
      broadcastType: undefined,
    };
    this.changeCampaige = this.changeCampaige.bind(this);
  }
  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    //Analytics Card Data
    fetch(
      "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/analytics",
      { headers: { Authorization: "Bearer " + accessToken } }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ apiData: data });
      });
  }

  //Select Event
  changeCampaige = (value) => {
    if (value === undefined) {
      this.setState({
        totalBroadcastInCampaing: 0,
        delivered: 0,
        sent: 0,
        failed: 0,
        broadcastType: value,
      });
    } else {
      var event = this.props.events.filter((event) => event._id === value);
      let totalSent = 0;
      let totalFailed = 0;
      this.setState({ broadcastType: event[0].name });
      if (event[0].broadcastMessages.length) {
        event[0].broadcastMessages.map((message) => {
          message.allrecipients.map((recipient) => {
            if (recipient.status === "sent") {
              totalSent = totalSent + 1;
            } else {
              totalFailed = totalFailed + 1;
            }
          });
          selectedEvent = event[0].name;
          reportData = [
            {
              event: event[0].name,
              totalBroadcasts: event[0]?.broadcastMessages.length,
              totalSentMessages: totalSent,
              totalFailedMessages: totalFailed,
            },
          ];
          this.setState({
            totalBroadcastInCampaing: event[0]?.broadcastMessages.length,
            delivered: 121,
            sent: totalSent,
            failed: totalFailed,
          });
        });
      } else {
        this.setState({
          totalBroadcastInCampaing: 0,
          delivered: 0,
          sent: 0,
          failed: 0,
        });
      }
    }
  };

  render() {
    const data = [
      {
        type: "Message Sent",
        value: this.state.sent,
      },
      {
        type: "Message Failed",
        value: this.state.failed,
      },
    ];
    const config = {
      appendPadding: 10,
      data,
      angleField: "value",
      colorField: "type",
      radius: 0.6,
      color: ({ type }) => {
        if (type === "Message Sent") {
          return "#5678e8";
        }
        return "#e36056";
      },
      label: {
        type: "inner",
        offset: "-30%",
        style: {
          fontSize: 0,
          textAlign: "center",
        },
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    };
    const headers = [
      { label: "Event", key: "event" },
      { label: "Total Broadcasts", key: "totalBroadcasts" },
      { label: "Total Sent Messages", key: "totalSentMessages" },
      { label: "Total Failed Messages", key: "totalFailedMessages" },
    ];
    return (
      <div>
        <div className="card-body">
          <div className="d-flex justify-content-center">
            <h5 className="text-secondary mb-3">Total Events</h5>
          </div>
          <CRow>
            <CSVLink
              data={reportData}
              headers={headers}
              className="float-end btn-dwn"
              filename={"event-report.csv"}
            >
              {this.state.broadcastType === undefined ? (
                <div className="float-end dwnld-btn">
                  <Button
                    size="large"
                    type="text"
                    disabled={this.state.broadcastType === undefined}
                  >
                    <DownloadOutlined />
                  </Button>
                </div>
              ) : (
                <Tooltip title="Export Report to csv">
                  <div className="float-end dwnld-btn">
                    <Button size="large" type="text">
                      <DownloadOutlined />
                    </Button>
                  </div>
                </Tooltip>
              )}
            </CSVLink>
          </CRow>
          <Row gutter={[24, 8]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                <div
                  style={{
                    border: "none",
                    height: "90px",
                  }}
                >
                  <div className="card-body">
                    <div className="select_event">
                      <span className="count">
                        <div className="count-cards-select">
                          <Select
                            style={{ width: "100%" }}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                ?.toLowerCase()
                                .localeCompare(optionB.children?.toLowerCase())
                            }
                            name="broadcastType"
                            placeholder="Select Event"
                            id="broadcastType"
                            onChange={this.changeCampaige}
                          >
                            {this.props.events.map((item) => {
                              return (
                                <Option value={item._id}>{item.name}</Option>
                              );
                            })}
                          </Select>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  border: "none",
                  height: "90px",
                }}
              >
                <div className="card-body" style={{ margin: "-13px 0 0 0" }}>
                  <div className="details">
                    <span className="count ms-3">
                      <span className="name-cloud ">
                        Total number of Broadcasts
                      </span>
                      <div className="count-cards-select">
                        <Tag style={{ fontSize: "initial" }} color="green">
                          {this.state.totalBroadcastInCampaing}
                        </Tag>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ margin: "-50px 0 0 0" }}>
            <Pie {...config} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  dashboardData: state.users.dashboardData,
  events: state.events.events,
});
export default connect(mapStateToProps, {
  getAllEvents,
})(EventPieChart);
