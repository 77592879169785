import "./App.css";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Home from "./ui-components/layouts/Home";
import SignIn from "./ui-components/auth/SignIn";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.min.css";
import { Provider } from "react-redux";
import store from "./store";
import { Auth, Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";
import "@ant-design/flowchart/dist/index.css";
import AdminDashboard from "./ui-components/dashboard/AdminDashboard";
import { brand } from "./util/constants";

function App() {
  const [currentUser, setCurrentUser] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const [isLogged, setIsLogged] = useState(false);
  const updateCurrentUser = async (newUser) => {
    if (newUser) {
      await setCurrentUser(newUser);
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      setIsLogged(true);
      setCurrentUser(user);
      setIsLoaded(false);
    } catch (err) {
      setIsLogged(false);
      setCurrentUser(null);
      navigate("/");
      setIsLoaded(true);
    }
  };

  const onHubAuth = (data) => {
    const { payload } = data;
    if (payload.event !== "signIn") {
      updateCurrentUser();
    }
  };

  useEffect(() => {
    document.title = brand.NAME;
    updateCurrentUser();
    Hub.listen("auth", onHubAuth);
  }, []);

  return (
    <Provider store={store}>
      <AmplifyProvider>
        <Routes location={location}>
          <Route
            exact
            path="/"
            element={isLogged ? <Navigate to="/dashboard" /> : <Home />}
          />
          <Route
            exact
            path="/dashboard"
            element={
              isLogged ? (
                <AdminDashboard location={location} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/analytics"
            element={
              isLogged ? (
                <AdminDashboard location={location} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/feedback"
            element={
              isLogged ? (
                <AdminDashboard location={location} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/query"
            element={
              isLogged ? (
                <AdminDashboard location={location} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/register"
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path="/events/viewbroadcast"
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path="/groups/customgroupbroadcast/viewbroadcastMessage"
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path="/events/viewbroadcast/broadcast"
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path="/groups"
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path="/events"
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path="/groups/customgroupbroadcast"
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path="/scheduleBroadcast"
            element={
              isLogged ? (
                <AdminDashboard location={location} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/scheduledBroadcast"
            element={
              isLogged ? (
                <AdminDashboard location={location} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/conversation"
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
        </Routes>
      </AmplifyProvider>
    </Provider>
  );
}

export default App;
