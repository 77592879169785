import React, { useEffect, useState } from "react";
import {
  Tabs,
  Table,
  Spin,
  Button,
  Modal,
  message,
  Typography,
  Tooltip,
} from "antd";
import BreadCrumbs from "../../BreadCrumbs";
import axios from "axios";
import Query from "./query";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { Auth } from "aws-amplify";
import { FilterOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

function AntdTabComponent(props) {
  const [activeTab, setActiveTab] = useState("event");
  const [eventApiData, setEventApiData] = useState([]);
  const [groupApiData, setGroupApiData] = useState([]);
  const [generalApiData, setGeneralApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generalModal, setGeneralModal] = useState(false);
  const [generalRecord, setGeneralRecord] = useState();
  const [reply, setReply] = useState("");
  const [sendButtonLoading, setSendButtonLoading] = useState(false);
  const [genLoading, setGenLoading] = useState(false);
  const [isHandleFilterCalled, setIsHandleFilterCalled] = useState(false);
  const [beforeFilterData, setBeforeFilterData] = useState([]);
  const [isFilterEvent, setIsFilterEvent] = useState(false);
  const [beforFilterEvent, setBeforeFilterEvent] = useState([]);
  const [isFilterGroup, setIsFilterGroup] = useState(false);
  const [beforeFilterGroup, setBeforeFilterGroup] = useState([]);

  const handleFilter = () => {
    if (!isHandleFilterCalled) {
      const data = generalApiData;
      const updateData = [];
      for (let i = 0; i < data.length; i++) {
        if (!data[i].isRespond) {
          updateData.push(data[i]);
        }
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].isRespond) {
          updateData.push(data[i]);
        }
      }
      console.log("updated dataaaaaa", updateData);
      setGeneralApiData(updateData);
      setIsHandleFilterCalled(true);
    } else {
      setGeneralApiData(beforeFilterData);
      setIsHandleFilterCalled(false);
    }
  };
  const handleFilterEvent = () => {
    if (!isFilterEvent) {
      const data = eventApiData;
      const updateEventdata = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].status === "pending") {
          updateEventdata.push(data[i]);
        }
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].status !== "pending") {
          updateEventdata.push(data[i]);
        }
      }
      setEventApiData(updateEventdata);
      setIsFilterEvent(true);
    } else {
      setEventApiData(beforFilterEvent);
      setIsFilterEvent(false);
    }
  };
  const handleFilterGroup = () => {
    if (!isFilterGroup) {
      const data = groupApiData;
      const updatedGroup = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].status === "pending") {
          updatedGroup.push(data[i]);
        }
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].status !== "pending") {
          updatedGroup.push(data[i]);
        }
      }
      setGroupApiData(updatedGroup);
      setIsFilterGroup(true);
    } else {
      setGroupApiData(beforeFilterGroup);
      setIsFilterGroup(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      try {
        setLoading(true);
        setGenLoading(true);
        const response = await axios.get(
          "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/query",
          { headers: { Authorization: "Bearer " + accessToken } }
        );
        let newData = JSON.parse(response.data.body);
        console.log("Response Event====> ", newData.event);
        console.log("Response group====> ", newData.group);
        setEventApiData(newData.event);
        setBeforeFilterEvent(newData.event);
        setGroupApiData(newData.group);
        setBeforeFilterGroup(newData.group);

        setLoading(false);
      } catch (error) {
        console.log("Error===>", error);
      }
      try {
        const generalResp = await axios.get(
          "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/conversation/general",
          { headers: { Authorization: "Bearer " + accessToken } }
        );
        console.log("General response", generalResp.data.body);

        setGeneralApiData(generalResp.data.body);
        setBeforeFilterData(generalResp.data.body);
        setGenLoading(false);
      } catch (error) {
        console.log("Error===>", error);
      }
    };
    fetchData();
    setInterval(fetchData, 600000);
  }, []);

  const eventColumns = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      align: "center",
    },
    {
      title: "Broadcast Message",
      dataIndex: "broadcastMessage",
      key: "broadcastMessage",
      align: "center",
    },
    // { title: "Mobile", dataIndex: "phoneNumber", key: "phoneNumber" },
    // { title: "Query", dataIndex: "query", key: "query" },
    // { title: "Queried By", dataIndex: "userName", key: "userName" },
    // { title: "Status", dataIndex: "status", key: "status" },
    {
      title: (
        <>
          Queries
          <Tooltip title="Filter">
            <FilterOutlined
              onClick={handleFilterEvent}
              style={{ marginLeft: "10px" }}
            />
          </Tooltip>
        </>
      ),
      dataIndex: "",
      align: "center",
      render: (text) => (
        <Query
          broadcastId={text.eventMessageId}
          queryIsExist={text.query.length > 0}
          queryLength={
            text.query !== null
              ? typeof text.query === "object"
                ? text.query.filter((queries) => queries.status === "pending")
                    .length
                : text.status === "pending"
                ? 1
                : 0
              : 0
          }
        />
      ),
    },
  ];

  const groupColumns = [
    {
      title: "Group Name",
      dataIndex: "groupName",
      key: "groupName",
      align: "center",
    },
    {
      title: "Broadcast Message",
      dataIndex: "broadcastMessage",
      key: "broadcastMessage",
      align: "center",
    },
    // { title: "Query", dataIndex: "query", key: "query" },
    // { title: "Queried By", dataIndex: "userName", key: "userName" },
    // { title: "Status", dataIndex: "status", key: "status" },
    {
      title: (
        <>
          Queries
          <Tooltip title="Filter">
            <FilterOutlined
              onClick={handleFilterGroup}
              style={{ marginLeft: "10px" }}
            />
          </Tooltip>
        </>
      ),
      dataIndex: "",
      align: "center",
      render: (text) => (
        <Query
          broadcastId={text.groupMessageId}
          queryIsExist={text.query.length > 0}
          queryLength={
            text.query !== null
              ? typeof text.query === "object"
                ? text.query.filter((queries) => queries.status === "pending")
                    .length
                : text.status === "pending"
                ? 1
                : 0
              : 0
          }
        />
      ),
      // sorter: (a, b) =>
      //   a.query.filter((queries) => queries.status === "pending").length -
      //   b.query.filter((queries) => queries.status === "pending").length,
      // defaultSortOrder: "descend",
      // sortDirections: ["descend"],
    },
    // { title: "Action", dataIndex: "groupaction", key: "groupaction" },
  ];

  const showModal = (record) => {
    setGeneralRecord(record);
    setGeneralModal(true);
  };

  const handleCancel = () => {
    setGeneralModal(false);
  };

  const handleReplyChange = (e) => {
    setReply(e.target.value);
  };

  const sendReply = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setSendButtonLoading(true);
    const replyObject = {
      message: reply,
      mobileNumber: generalRecord.mobileNumber,
      msgId: generalRecord.msgId,
    };
    let replyResponse = await axios.post(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/conversation`,
      replyObject,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    console.log("Reply Response", replyResponse);
    if (replyResponse.data.statusCode === 200) {
      message.success("Reply sent successfully");
      handleCancel();
      setSendButtonLoading(false);
    } else {
      console.log("Error");
    }
  };
  const generalColumns = [
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile",
      dataIndex: "",
      key: "",
      render: (record) => (
        <Typography.Text>+{record.mobileNumber}</Typography.Text>
      ),
    },
    {
      title: "Time",
      dataIndex: "",
      key: "",
      render: (record) => <>{moment(record.timeStamp).format("DD-MM-YY LT")}</>,
    },
    {
      title: (
        <>
          Reply
          <Tooltip title="Filter">
            <FilterOutlined
              onClick={handleFilter}
              style={{ marginLeft: "10px" }}
            />
          </Tooltip>
        </>
      ),

      dataIndex: "",
      align: "center",
      render: (record) => (
        <>
          {record.isRespond === true ? (
            <>
              <Button
                disabled
                style={{ color: "#289200", borderColor: "#289200" }}
              >
                Replied
              </Button>
            </>
          ) : (
            <>
              <Button
                style={{ color: "red", borderColor: "red" }}
                onClick={() => showModal(record)}
              >
                Reply
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <div className="container dashboard statistics-card">
      <div className="row ">
        <div className="col-8">
          <h2 className="page-title">Query</h2>
          <BreadCrumbs location={props.location} />
        </div>
      </div>
      <div className="mt-4">
        <div className="card">
          <div className="card-body p-5">
            <div>
              <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
                <TabPane tab="Event" key="event">
                  {loading ? (
                    <div className="text-center py-5">
                      <Spin />
                    </div>
                  ) : (
                    <Table dataSource={eventApiData} columns={eventColumns} />
                  )}
                </TabPane>
                <TabPane tab="Group" key="group">
                  {loading ? (
                    <div className="text-center py-5">
                      <Spin />
                    </div>
                  ) : (
                    <Table dataSource={groupApiData} columns={groupColumns} />
                  )}
                </TabPane>
                <TabPane tab="General" key="general">
                  {genLoading ? (
                    <div className="text-center py-5">
                      <Spin />
                    </div>
                  ) : (
                    <Table
                      dataSource={generalApiData}
                      columns={generalColumns}
                    />
                  )}
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={"General Message Reply"}
        open={generalModal}
        destroyOnClose={true}
        // onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              Replying to - {generalRecord?.name} <b></b>
              <br />
              Message - {generalRecord?.message}
              <b></b>
            </div>
          </div>

          <br />

          <TextArea
            name="textarea"
            placeholder="Reply to Query"
            onChange={handleReplyChange}
          />
          <div className="sendReply">
            <div>
              <Button
                type="primary"
                loading={sendButtonLoading}
                onClick={() => {
                  sendReply();
                }}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AntdTabComponent;
