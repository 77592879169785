import React, { Component } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { getAllEvents } from "../../../actions/eventActions";
import {
  getBusinessGroups,
  getBusinessUnits,
  getLocations,
  getAllUsers,
  getAllGroups,
  getValidation,
} from "../../../actions/userAction";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Tooltip,
  DatePicker,
  TimePicker,
} from "antd";
import BroadcastLists from "../broadcast/BroadcastLists";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import { Auth } from "aws-amplify";
const { Option } = Select;
dayjs.extend(weekday);
dayjs.extend(localeData);

class ScheduleComponent extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      navigate: false,
      message: "",
      groups: null,
      update: false,
      businessGroupName: null,
      businessUnitName: null,
      locationCode: null,
      eventName: null,
      groupName: null,
      groupActive: false,
      scheduleDate: null,
      scheduleTime: null,
      errors: {},
      broadcastFieldValid: false,
      dateFieldValid: false,
      timeFieldValid: false,
      disabledType: false,
      broadcastType: "event",
      eventId: "",
      selectEvent: true,
      updatedData: null,
    };
  }

  //Get Details
  getDetails = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    await this.props.getBusinessGroups(accessToken); //Get Business Group
    await this.props.getBusinessUnits(accessToken); //Get Business Unit
    await this.props.getLocations(accessToken); //Get Locations
    await this.setState({ locationCode: 102 });
    this.formRef.current?.setFieldsValue({
      locationCode: "Indore-Crystal IT Park-DC",
    });
    if (this.props.locations?.length > 0) {
      let userObj = {
        businessGroupName: this.state.businessGroupName,
        businessUnitName: this.state.businessUnitName,
        locationCode: 102,
      };
      await this.props.getAllUsers(userObj.accessToken); //Get all users
    }
  };
  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.props.getValidation(true, accessToken); //Validations
    this.props.getAllEvents(accessToken); //Get All Events
    this.getDetails();
    if (this.props.location.state !== null) {
      let updateMessageAndRecipients = {
        message: this.props.location.state.broadcastMessage,
        selectedRecipients: this.props.location.state.recipients,
        scheduleId: this.props.location.state.scheduleId,
        broadcastId: this.props.location.state.broadcastId,
      };

      let selectDate = new Date(this.props.location.state.selectDateAndTime);
      let arrangeDate = `${selectDate.toLocaleString("default", {
        month: "short",
      })} ${selectDate.getDate()} ${selectDate.getFullYear()}`;
      this.setState({ scheduleDate: arrangeDate });

      let selectTime = new Date(this.props.location.state.selectDateAndTime);
      let arrangeTime = `${selectTime.getHours()}:${selectTime.getMinutes()}`;
      this.setState({ scheduleTime: arrangeTime });
      this.setState({
        update: true,
        updatedData: updateMessageAndRecipients,
      });
      if (this.props.location.state.eventName !== null) {
        this.setState({
          eventName: this.props.location.state.eventName,
          broadcastType: "event",
        });
      }
    }
  }

  //Date Change
  onDateChange = (selectedDate) => {
    if (!!selectedDate) {
      const selectDate = new Date(selectedDate);
      const arrangeDate = `${selectDate.toLocaleString("default", {
        month: "short",
      })} ${selectDate.getDate()} ${selectDate.getFullYear()}`;
      this.setState({ scheduleDate: arrangeDate });
    } else {
      this.setState({ scheduleDate: null });
    }
  };

  //Time Change
  onTimeChange = (selecttime, timeString) => {
    if (!!selecttime) {
      const selectTime = new Date(selecttime);
      const arrangeTime = `${selectTime.getHours()}:${selectTime.getMinutes()}`;
      this.setState({ scheduleTime: arrangeTime });
    } else {
      this.setState({ scheduleTime: null });
    }
  };

  //On Submit Form
  onSubmit = async (values) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let userObj = {
      businessGroupName: this.state.businessGroupName,
      businessUnitName: this.state.businessUnitName,
      locationCode: this.state.locationCode,
    };
    this.props.getAllUsers(userObj, accessToken);
  };

  //Group Change
  onGroupChange = (event) => {
    this.setState({
      businessGroupName: event ?? null,
    });
  };

  //Business Unit Change
  onBusinessUnitChange = (event) => {
    this.setState({
      businessUnitName: event ?? null,
    });
  };

  //Navigate to schedule Events
  navigateToScheduledEvents = () => {
    this.setState({ navigate: true });
  };

  //Event Broadcast Change
  onEventBroadcastChange = (event) => {
    this.setState({
      eventName: event ?? null,
    });
  };

  //Group Broadcast Change
  onGroupBroadcastChange = (group) => {
    this.setState({
      groupName: group ?? null,
    });
  };

  //Location Change
  onLocationChange = (event) => {
    this.setState({
      locationCode: event ?? null,
    });
  };

  //Change Broadcast
  changeBroadcast = (value) => {
    this.setState({ allFieldValid: true });
    if (value === "event") {
      this.setState({
        groupActive: false,
        broadcastType: value,
        selectEvent: true,
        disabledType: false,
      });
    } else if (value === "group") {
      this.setState({
        groupActive: true,
        broadcastType: value,
        selectEvent: false,
        disabledType: false,
      });
    } else {
      this.setState({
        broadcastType: null,
        disabledType: true,
      });
    }
  };

  render() {
    const disabledDate = (current) => {
      return current && current < dayjs().endOf("day").subtract(1, "day");
    };
    return (
      <div className="container dashboard statistics-card">
        {this.state.navigate ? <Navigate to="/scheduledBroadcast" /> : ""}
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={19} xl={19}>
            <h2 className="page-title">Schedule Broadcasts</h2>
            <BreadCrumbs location={this.props.location} />
          </Col>
        </Row>
        <Form
          ref={this.formRef}
          name="add-Members"
          className="add-Members"
          onFinish={this.onSubmit}
          initialValues={
            this.props.location.state !== null
              ? this.props.location.state
              : null
          }
        >
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="d-flex flex-row">
                <div className="pages-header pages-header-scheduler"></div>
              </div>
            </Col>
          </Row>
          <Row className="timming-row">
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <Form.Item name="broadcastType">
                <Select
                  style={{ width: 250 }}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      ?.toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  name="broadcastType"
                  placeholder="Select Broadcast Type"
                  id="broadcastType"
                  defaultValue={this.state.broadcastType}
                  onChange={this.changeBroadcast}
                  value={this.state.broadcastType}
                >
                  <Option value="event">Event</Option>
                  <Option value="group">Group</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              {this.state.selectEvent && (
                <div className="d-flex flex-row">
                  <Form.Item name="eventName">
                    <Select
                      style={{ width: 250 }}
                      showSearch
                      allowClear
                      disabled={this.state.disabledType}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          ?.toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      name="eventName"
                      placeholder="Select Event"
                      id="eventName"
                      onChange={this.onEventBroadcastChange}
                      value={this.state.eventName}
                    >
                      {this.props.events?.map((group) => (
                        <Option value={group._id}>{group.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              )}
              {!this.state.selectEvent && (
                <div className="d-flex flex-row">
                  <Form.Item name="groupName">
                    <Select
                      style={{ width: 250 }}
                      showSearch
                      disabled={this.state.disabledType}
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          ?.toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      name="groupName"
                      placeholder="Select Group"
                      id="groupName"
                      onChange={this.onGroupBroadcastChange}
                      value={this.state.groupName}
                    >
                      {this.props.groups?.map((group) => (
                        <Option value={group._id}>{group.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              )}
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="d-flex flex-row">
                <Form.Item name="selectDate">
                  <DatePicker
                    defaultValue={
                      this.props.location.state !== null
                        ? dayjs(this.props.location.state.selectDateAndTime)
                        : null
                    }
                    format="YYYY-MM-DD"
                    disabledDate={disabledDate}
                    placeholder="Select Date (YYYY-mm-DD)"
                    className="ant-picker-yash"
                    name="date"
                    value={this.state.scheduleDate}
                    onChange={this.onDateChange}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="d-flex flex-row">
                <Form.Item name="selectTime">
                  <TimePicker
                    defaultValue={
                      this.props.location.state !== null
                        ? dayjs(this.props.location.state.selectDateAndTime)
                        : null
                    }
                    placeholder="Select Time (24-hour format)"
                    className="ant-picker-yash"
                    name="time"
                    value={this.state.scheduleTime}
                    onChange={this.onTimeChange}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row className="title-row">
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="d-flex flex-row">
                <Form.Item name="businessGroupName">
                  <Select
                    style={{ width: 250 }}
                    showSearch
                    disabled={this.state.groupActive}
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        ?.toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    name="businessGroupName"
                    placeholder="Select Business Group"
                    id="businessGroupName"
                    onChange={this.onGroupChange}
                    value={this.state.businessGroupName}
                  >
                    {this.props.businessGroups?.map((group) => (
                      <Option value={group.businessGroup}>
                        {group.businessGroup}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="d-flex flex-row">
                <Form.Item name="businessUnitName">
                  <Select
                    style={{ width: 250 }}
                    showSearch
                    allowClear
                    disabled={this.state.groupActive}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        ?.toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    name="businessUnitName"
                    placeholder="Select Business Unit"
                    id="businessUnitName"
                    onChange={this.onBusinessUnitChange}
                    value={this.state.businessUnitName}
                  >
                    {this.props.businessUnits?.map((group) => (
                      <Option value={group.businessUnit}>
                        {group.businessUnit}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <div className="d-flex flex-row">
                <Form.Item name="locationCode">
                  <Select
                    style={{ width: 250 }}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        ?.toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    name="locationCode"
                    placeholder="Select Location"
                    id="locationCode"
                    onChange={this.onLocationChange}
                    value={this.state.locationCode}
                  >
                    {this.props.locations?.map((location) => (
                      <Option key={location.locationCode}>
                        {location.locationName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>

            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
              <Form.Item>
                <Tooltip title="search">
                  <Button
                    className="btn-admin btn-subCategory close-modal"
                    icon={<SearchOutlined />}
                    style={{ display: "flex", alignItems: "center" }}
                    htmlType="submit"
                  >
                    Search
                  </Button>
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <BroadcastLists
          update={this.state.update}
          updatedData={this.state.updatedData}
          users={this.props.users}
          eventId={this.state.eventName}
          groupId={this.state.groupName}
          type="Schedule"
          broadcastType={this.state.broadcastType}
          scheduleDate={this.state.scheduleDate}
          scheduleTime={this.state.scheduleTime}
        />
      </div>
    );
  }
}
ScheduleComponent.propTypes = {
  location: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  errors: state.errors,
  businessGroups: state.users.businessGroups,
  businessUnits: state.users.businessUnits,
  locations: state.users.locations,
  users: state.users.users,
  sendBroadcastMsg: state.users.sendBroadCastMsgResponse,
  events: state.events.events,
  groups: state.users.groups,
  valid: state.users.getValidation,
});
export default connect(mapStateToProps, {
  getBusinessGroups,
  getBusinessUnits,
  getLocations,
  getAllUsers,
  getAllEvents,
  getAllGroups,
  getValidation,
})(ScheduleComponent);
