import {
  GET_ALL_EVENTS,
  CREATE_EVENT,
  GET_ALL_SCHEDULES,
  GET_QUERIES,
  DELETE_OPTION,
} from "../actions/type";

const initialState = {
  events: [],
  schedules: [],
  replyToQuery: [],
  queries: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_EVENT:
      return {
        ...state,
        events: [action.payload, ...state.events],
      };
    case GET_ALL_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case GET_ALL_SCHEDULES:
      return {
        ...state,
        schedules: action.payload,
      };
    case GET_QUERIES:
      return {
        ...state,
        queries: action.payload,
      };
    case DELETE_OPTION:
      return {
        ...state,
        events: [action.payload, ...state.events],
      };
    default:
      return state;
  }
}
