import axios from "axios";
import {
  GET_ERRORS,
  CREATE_EVENT,
  GET_ALL_EVENTS,
  GET_ALL_SCHEDULES,
  REPLY_TO_QUERY,
  GET_QUERIES,
} from "./type";
import Pusher from "pusher-js";
import { message } from "antd";

//Get All Events
export const getAllEvents = (accessToken) => async (dispatch) => {
  try {
    let response = await axios.get(
      "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/event",
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    dispatch({
      type: GET_ALL_EVENTS,
      payload: response.data,
    });
    var pusher = new Pusher("20cf801b69f19fcea0a6", {
      cluster: "ap2",
    });
    const channelName = "whatsapp_employee_connect";
    var channel = pusher.subscribe(channelName);
    channel.bind("my_event", async function (data) {
      let response = await axios.get(
        "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/event",
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      dispatch({
        type: GET_ALL_EVENTS,
        payload: response.data,
      });
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Create Event
export const createEvent = (event, accessToken) => async (dispatch) => {
  try {
    let resp = await axios.post(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/event`,
      event,
      { headers: { Authorization: "Bearer " + accessToken } }
    );

    dispatch({
      type: CREATE_EVENT,
      payload: resp.data,
    });
    console.log("resp", resp);
    if (resp.status === 200) {
      message.success(`Event Created Successfully`);
    } else {
      message.error(`Last event not created`);
    }
  } catch (error) {
    console.log("#error", error);
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Generate Upload Document URL
export const generateUploadDocumentUrl =
  (obj, accessToken) => async (dispatch) => {
    try {
      let resp = await axios.post(
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/presignedurl`,
        obj,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
    } catch (error) {
      console.log("#error", error);
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      });
    }
  };

//Get All Scheduled Broadcasts
export const getAllSchedules = (accessToken) => async (dispatch) => {
  try {
    let resp = await axios.get(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/schedules`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    dispatch({
      type: GET_ALL_SCHEDULES,
      payload: resp.data.body,
    });
  } catch (error) {
    console.log("#error", error);
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Sending Reply to Query
export const sendReplyToQuery = (replyObj, accessToken) => async (dispatch) => {
  try {
    let resp = await axios.post(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/queries`,
      replyObj,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    if (resp.data !== null) {
      dispatch({
        type: REPLY_TO_QUERY,
        payload: resp.data.body,
      });
      if (resp.data.statusCode === 200) {
        message.success(`Last reply sent successfully`);
        let response = await axios.get(
          `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/queries?broadcastId=${resp.data.body._id}`,
          { headers: { Authorization: "Bearer " + accessToken } }
        );
        dispatch({
          type: GET_QUERIES,
          payload: response.data.data,
        });
      } else {
        message.error(`last reply not sent`);
      }
    }
  } catch (error) {
    console.log("#error", error);
    message.error(`something went wrong`);
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Get All Queries by Broadcst ID
export const getAllQueriesByBroadcastId =
  (broadcastId, accessToken) => async (dispatch) => {
    try {
      let response = await axios.get(
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/queries?broadcastId=${broadcastId}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      dispatch({
        type: GET_QUERIES,
        payload: response.data.data,
      });
      var pusher = new Pusher("20cf801b69f19fcea0a6", {
        cluster: "ap2",
      });
      const channelName = "whatsapp_employee_connect";
      var channel = pusher.subscribe(channelName);
      channel.bind("my_event", async function (data) {
        let response = await axios.get(
          `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/queries?broadcastId=${data.data.broadcastId}`,
          { headers: { Authorization: "Bearer " + accessToken } }
        );
        dispatch({
          type: GET_QUERIES,
          payload: response.data.data,
        });
      });
    } catch (error) {
      console.log("#error", error);
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      });
    }
  };
