// ***************************** Billing ****************************//

const awsConfig = {
  Auth: {
    identityPoolId: "ap-south-1:94546cc2-8d3d-4089-b3b9-8fa3d2a8966c",
    region: "ap-south-1",
    userPoolId: "ap-south-1_J7Jgnhn1c",
    userPoolWebClientId: "5vg4ud5r40uoc6ktjdc9onj729",
  },
  oauth: {
    domain: "prod-employee-connect.auth.ap-south-1.amazoncognito.com",
    scope: ["email", "openid"],
    redirectSignIn: window.origin,
    redirectSignOut: window.origin,
    responseType: "code",
  },
};

export default awsConfig;