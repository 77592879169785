import React, { Component } from "react";
import {
  AlertOutlined,
  MessageOutlined,
  FieldTimeOutlined,
  QuestionCircleOutlined,
  FlagOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { getDashboardData } from "../../../actions/userAction";
import { getAllEvents } from "../../../actions/eventActions";
import moment from "moment";
import "../../../sample.css";
import { Auth } from "aws-amplify";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import BreadCrumbs from "../../BreadCrumbs";

class WabaDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      loadingBroadcast: true,
      analyticsApidata: null,
    };
  }

  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    fetch(
      "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/analytics",
      { headers: { Authorization: "Bearer " + accessToken } }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ analyticsApidata: data });
      });

    this.props.getDashboardData(accessToken); //Dashboard Cards Data
    this.props.getAllEvents(accessToken); //Fetch All Events
    this.checkExceedLimit();
    this.setState({ loadingBroadcast: false });
  }

  //Check Exceed Limit
  checkExceedLimit() {
    let exceedLimit = 0;
    this.props.events.map((event) => {
      var dateLimit = moment(event.created_at);
      var now = moment();
      if (event.limit !== null || (event !== " " && now > dateLimit)) {
        exceedLimit++;
      }
    });
  }

  render() {
    // Logic for last event
    const currentDate = new Date().toISOString().split("T")[0];
    const lastEvents = this.props.events.filter(
      (event) => event.eventDate < currentDate
    );

    const sortByEventDate = (lastEvents) => {
      const sorter = (a, b) => {
        return (
          new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime()
        );
      };
      lastEvents.sort(sorter);
    };
    sortByEventDate(lastEvents);

    const lastEventName = lastEvents.length
      ? lastEvents.at(-1).name
      : "No event present";
    const upcomingEvents = this.props.events.filter(
      (event) => event.eventDate > currentDate
    );

    const sortUpcomingEventsByDate = (upcomingEvents) => {
      const sorter = (a, b) => {
        return (
          new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime()
        );
      };
      upcomingEvents.sort(sorter);
    };
    sortUpcomingEventsByDate(upcomingEvents);
    const upcomingEventName = upcomingEvents.length
      ? upcomingEvents.at(0).name
      : "No event present";
    return (
      <>
        <CRow>
          <div>
            {" "}
            <h2 className="page-title">Dashboard</h2>
            <BreadCrumbs location={this.props.location} />
          </div>
        </CRow>
        <div className="dashboard-new">
          <div>
            <CRow>
              <CCol xs={12} sm={6} md={6} lg={6} xl={4}>
                <div className="card-name-and-style1" color="primary">
                  <>
                    <CRow>
                      <CCol>
                        <h4 style={{ color: "white" }}>Total Events</h4>
                        <h1 style={{ color: "white" }}>
                          <b>
                            {" "}
                            {this.state.analyticsApidata
                              ? this.state.analyticsApidata?.body?.events
                                  ?.totalEvents
                              : ""}
                          </b>
                        </h1>
                      </CCol>
                      <CCol className="custom-flex">
                        <div>
                          <FlagOutlined
                            style={{
                              float: "right",
                              fontSize: "300%",
                              color: "white",
                            }}
                          ></FlagOutlined>
                        </div>
                      </CCol>
                    </CRow>
                  </>
                </div>
              </CCol>
              <CCol xs={12} sm={6} md={6} lg={6} xl={4}>
                <div className="card-name-and-style2" color="primary">
                  <>
                    <CRow>
                      <CCol>
                        <h4 style={{ color: "white" }}>Upcoming Events</h4>
                        <h2 style={{ color: "white" }}>
                          <b>{upcomingEventName}</b>
                        </h2>
                      </CCol>
                      <CCol className="custom-flex">
                        <div>
                          <AlertOutlined
                            style={{
                              float: "right",
                              fontSize: "300%",
                              color: "white",
                            }}
                          />
                        </div>
                      </CCol>
                    </CRow>
                  </>
                </div>
              </CCol>
              <CCol xs={12} sm={6} md={6} lg={6} xl={4}>
                <div className="card-name-and-style6" color="primary">
                  <>
                    <CRow>
                      <CCol>
                        <h4 style={{ color: "white" }}>Last Event</h4>
                        <h2 style={{ color: "white" }}>
                          <b> {lastEventName}</b>
                        </h2>
                      </CCol>
                      <CCol className="custom-flex">
                        <div>
                          <FieldTimeOutlined
                            style={{
                              float: "right",
                              fontSize: "300%",
                              color: "white",
                            }}
                          />
                        </div>
                      </CCol>
                    </CRow>
                  </>
                </div>
              </CCol>
            </CRow>
          </div>

          <CRow>
            <CCol xs={12} sm={12} md={3} lg={9} xl={9}>
              <CCard className="mb-4" style={{ height: "270px" }}>
                <CCardBody className="p-4">
                  <CRow>
                    <CCol>
                      <CCardTitle class="cardTitle">Recent Events</CCardTitle>
                    </CCol>
                  </CRow>
                  <CTable align="middle" hover responsive>
                    <CTableHead style={{ color: "#2c384a61" }}>
                      <CTableRow>
                        <CTableHeaderCell>Event Title</CTableHeaderCell>
                        <CTableHeaderCell class="text-center">
                          No. of Broadcasts
                        </CTableHeaderCell>
                        <CTableHeaderCell class="text-center">
                          Date of Creation
                        </CTableHeaderCell>
                        <CTableHeaderCell class="text-center">
                          Created By
                        </CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {this.props.events.map((event, index) => (
                        <CTableRow key={index}>
                          <CTableDataCell>
                            <div>{event.name}</div>
                          </CTableDataCell>
                          <CTableDataCell
                            class="text-center"
                            style={{ color: "#4CAF50" }}
                          >
                            {event.broadcastMessages.length}
                          </CTableDataCell>
                          <CTableDataCell class="text-center">
                            {moment(event.created_at).format("MMMM Do YYYY")}
                          </CTableDataCell>

                          <CTableDataCell class="text-center">
                            {event.createdBy}
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol>
              <CRow>
                <CCol xs={12} sm={6} md={6} lg={6} xl={12}>
                  <div className="card-name-and-style1" color="primary">
                    <>
                      <CRow>
                        <CCol>
                          <h4 style={{ color: "white" }}>Total Active Users</h4>
                          <h1 style={{ color: "white" }}>
                            <b>
                              {this.state.analyticsApidata
                                ? this.state.analyticsApidata?.body?.activeUsers
                                    .activeUsers
                                : ""}
                            </b>
                          </h1>
                        </CCol>
                        <CCol className="custom-flex">
                          <div>
                            <MessageOutlined
                              style={{
                                float: "right",
                                fontSize: "300%",
                                color: "white",
                              }}
                            />
                          </div>
                        </CCol>
                      </CRow>
                    </>
                  </div>
                </CCol>
                <CCol xs={12} sm={6} md={6} lg={6} xl={12}>
                  <div className="card-name-and-style2" color="primary">
                    <>
                      <CRow>
                        <CCol>
                          <h4 style={{ color: "white" }}>Pending Queries</h4>
                          <h1 style={{ color: "white" }}>
                            <b>
                              {this.state.analyticsApidata
                                ? this.state.analyticsApidata?.body
                                    ?.pendingQuery?.pendingqueries
                                : ""}
                            </b>
                          </h1>
                        </CCol>
                        <CCol className="custom-flex">
                          <div>
                            <QuestionCircleOutlined
                              style={{
                                float: "right",
                                fontSize: "300%",
                                color: "white",
                              }}
                            />
                          </div>
                        </CCol>
                      </CRow>
                    </>
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  dashboardData: state.users.dashboardData,
  events: state.events.events,
});
export default connect(mapStateToProps, {
  getDashboardData,
  getAllEvents,
})(WabaDashboard);
