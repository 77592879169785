import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Row, Select, Space, Table, Tabs, Tooltip ,Modal} from "antd";
import { Auth } from "aws-amplify";
import axios from "axios";
import { saveAs } from "file-saver";
import Pusher from "pusher-js";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { getAllQueriesByBroadcastId } from "../../../actions/eventActions";
import { getAllBroadCastStatusesbyBroadcastId } from "../../../actions/userAction";
import BreadCrumbs from "../../BreadCrumbs";
 
class ViewBroadcastMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingTable: true,
            initialData: null,
            tableData: null,
        };
    }
 
    // Method to fetch data from DB
    fetchData = async () => {
        this.setState({ loadingTable: true });
        await this.props.getAllBroadCastStatusesbyBroadcastId(this.props.location.state._id);
        this.setState({
            initialData: this.props.broadcastDetail?.allrecipients || [],
            tableData: this.props.broadcastDetail?.allrecipients || [],
            filterData: this.props.broadcastDetail?.allrecipients || [],
            loadingTable: false
        });
    };
 
    async componentDidMount() {
        await this.fetchData();
 
        // Initialize Pusher
        this.pusher = new Pusher("52f42b6764ad744f84ed", { cluster: "ap2", useTLS: true });
        this.channel = this.pusher.subscribe("messages");
        this.channel.bind("status-update", this.handleStatusUpdate);
        this.channel.bind("reaction-update", this.handleReactionUpdate);
    }
 
    // Updated Event Handlers for Pusher Events
handleStatusUpdate = async (data) => {
  console.log("handleStatusUpdate function is called")
  const { messageId, status } = data;
  this.setState((prevState) => {
      const updatedData = prevState.initialData.map((message) => {
          if (message.messageId && message.messageId.trim() === messageId.trim()) {
              return { ...message, status: status || message.status };
          }
          return message;
      });
      
      return { initialData: updatedData,tableData: updatedData};
  });
  // await this.fetchData();
};

handleReactionUpdate = async (data) => {
  console.log("handleReactionUpdate function is called")
  const { messageId, reaction } = data;
  this.setState((prevState) => {
      const updatedData = prevState.initialData.map((message) => {
          if (message.messageId && message.messageId.trim() === messageId.trim()) {
              return { ...message, reaction: reaction || message.reaction }; // Merge reaction without affecting status
          }
          return message;
      });
      return { initialData: updatedData,tableData: updatedData};
  });
  // await this.fetchData();
};

 
    // Refresh button to reload data
    refreshData = () => {
        this.fetchData(); // Re-fetch the latest data from DB
    };
 
    // Downloading Image
    downloadImage = async (key) => {
        let accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
        let response = await axios.get(
`https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/presignedurl?key=${key}`,
            { headers: { Authorization: "Bearer " + accessToken } }
        );
        saveAs(response.data.url);
    };
 
    // Handle Status Filter Change
    messageStatus = (value) => {
        let status;
        if (value === "sent" || value === "failed" || value === "read" || value === "delivered") {
            const allrecipients = this.state.tableData;
            status = allrecipients.filter((data) => data.status === value);
        } else {
            status = this.state.tableData;
        }
        this.setState({ initialData: status });
        
    };
 
    render() {
        const headers = [
            { label: "Message", key: "message" },
            { label: "Media", key: "media" },
            { label: "Number", key: "number" },
            { label: "Recipient Name", key: "recipientName" },
            { label: "Status", key: "status" },
            { label: "Reaction", key: "reaction" },
        ];
 
        const data = this.props.broadcastDetail?.allrecipients?.map((element) => {
            element.message = this.props.broadcastDetail.message;
            element.media = this.props.broadcastDetail.media;
            element.number = element.number + " ";
            return element;
        }) || [];
 
        const items = [
            { key: "1", value: "Status", label: "Status" },
            { key: "2", value: "sent", label: "Sent" },
            { key: "3", value: "failed", label: "Failed" },
            { key: "4", value: "delivered", label: "Delivered" },
            { key: "5", value: "read", label: "Read" },
        ];
 
        const messageStatisticsCols = [
            { title: "Number", dataIndex: "number", align: "left", width: "200px" },
            { title: "Name", dataIndex: "recipientName", align: "center" },
            {
              title: "Reaction",
              dataIndex: "reaction",
              align: "center",
              render: (reaction) =>
                  reaction ? (
                      <p className="text-success my-para">{reaction}</p>
                  ) : (
                      <p> -- </p>
                  ),
          },
          {
            title: (
                <Space size="middle">
                    <Select
                        onChange={this.messageStatus}
                        style={{ width: "110px" }}
                        defaultValue="Status"
                        options={items}
                    />
                </Space>
            ),
            dataIndex: "status",
            width: "200px",
            align: "center",
            render: (text) =>
                text === "read" || text === "sent" || text === "delivered" || text === "failed" ? (
                    <p className="text-success my-para">{text}</p>
                ) : (
                    <p className="text-danger">
                        Number is invalid or not registered with WhatsApp
                    </p>
                ),
        },
        ];
 
        return (
            <div className="container dashboard statistics-card">
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <div className="page-title">Message</div>
                        <BreadCrumbs location={this.props.location} />
                    </Col>
                    <Col span={12}>
                        <Space className="float-end">
                            <CSVLink
                                data={data}
                                headers={headers}
                                className="btn-dwn"
                                filename={"message-statistics-report.csv"}
                            >
                                <Tooltip title="Export to CSV">
                                    <Button size="large" type="text">
                                        <DownloadOutlined />
                                    </Button>
                                </Tooltip>
                            </CSVLink>
                            <Tooltip title="Refresh Data">
                                <Button size="large" type="text" onClick={this.refreshData}>
                                    <ReloadOutlined />
                                </Button>
                            </Tooltip>
                        </Space>
                    </Col>
                </Row>
                <div className="mt-4">
                    <div className="card">
                        <div className="card-body p-5">
                            <Tabs
                                defaultActiveKey="1"
                                onChange={this.onChange}
                                items={[
                                    {
                                        label: `Message Statistics`,
                                        key: "2",
                                        children: (
                                            <Table
                                                columns={messageStatisticsCols}
                                                dataSource={this.state.initialData}
                                                loading={this.state.loadingTable}
                                                rowKey="_id"
                                                align="left"
                                            />
                                        ),
                                    },
                                    {
                                        label: `Attachments`,
                                        key: "3",
                                        children: this.props.location.state.media ? (
                                            <Row className="title-row">
                                                <Col>{this.props.location.state.fileName}</Col>
                                                <Col xs={4} sm={4} md={4} lg={1} xl={1}></Col>
                                                <Col>
                                                    <button
                                                        type="button"
                                                        className="rounded btn-success"
                                                        onClick={() =>
                                                            this.downloadImage(
                                                            this.props.location.state.media
                                                            )
                                                        }
                                                    >
                                                        <DownloadOutlined />
                                                    </button>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <div>No Attachments</div>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
const mapStateToProps = (state) => ({
    errors: state.errors,
    groups: state.users.groups,
    broadcastDetail: state.users.broadcastDetail,
    sendBroadcastMsg: state.users.sendBroadCastMsgResponse,
queries: state.events.queries,
});
 
export default connect(mapStateToProps, {
    getAllQueriesByBroadcastId,
    getAllBroadCastStatusesbyBroadcastId,
})(ViewBroadcastMessage);
