import React from "react";
import { Breadcrumb } from "antd";
import { Link, useNavigate } from "react-router-dom";

const BreadCrumbs = ({ location }) => {
  const navigate = useNavigate();
  const loc = location;
  const pathname = loc.pathname;

  const pathnames = pathname.split("/").filter((item) => item);
  const capatilize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

  return (
    <div>
      <Breadcrumb>
        {pathnames.length > 0 ? (
          <Breadcrumb.Item>
            <b>
              <Link to="/">Home</Link>
            </b>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        )}

        {pathnames.map((name, index) => {
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Breadcrumb.Item>{capatilize(name)}</Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item>
              <label
                style={{ color: "#857fe8", fontWeight: "600" }}
                type="link"
                onClick={() => {
                  navigate(-(pathnames.length - 1));
                }}
              >
                {name}
              </label>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default BreadCrumbs;
