import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, Row, Table, Tabs, Tag, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Auth } from "aws-amplify";
import { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { sendReplyToQuery } from "../../../actions/eventActions";
import { deleteUserFromGroup, getAllBroadCastsbyGroupID, getAllGroups } from "../../../actions/userAction";
import BreadCrumbs from "../../BreadCrumbs";
import Query from "../query/query";
import Pusher from "pusher-js";

class CustomGroupBroadcast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      replyQuery: "",
      name: "",
      loadingTable: true,
      broadcastId: null,
      sendTemplateResource: false,
      pendingQueries: [],
      resolvedQueries: [],
      navigateToMessage: false,
      messageDetail: "",
      group: [],
      updated: true,
      modelOpen: false,
      openQueryDialog: false,
    };
  }

  //Query Dialog Visibility
  handleQueryDialogVisiblity = (isVisible) => {
    this.setState({
      openQueryDialog: isVisible,
    });
  };

  //Send Reply
  sendReply = async (data) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let replyObject = {
      queryId: data._id,
      userName: data.userName,
      phoneNumber: data.phoneNumber,
      broadcastId: this.state.broadcastId,
      reply: this.state.replyQuery,
      template: this.state.sendTemplateResource,
    };

    await this.props.sendReplyToQuery(replyObject, accessToken);
    await this.refreshQueries();
  };

fetchData = async () => {
    this.setState({ loadingTable: true });
    await this.props.getAllBroadCastsbyGroupID(this.props.location.state._id);
    this.setState({
        loadingTable: false
    });
};


handleQueryUpdate = async () => { await this.fetchData();};

async componentDidMount() {
    // Pusher.logToConsole = true;
    var pusher = new Pusher("52f42b6764ad744f84ed", {cluster: "ap2",useTLS:true,transports:["websockets"],protocol:8});
    const channelName = "messages";
    var channel = pusher.subscribe(channelName);
    channel.bind("query_update",this.handleQueryUpdate);
    let accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();

    if (this.state.updated) {
      this.setState({ updated: false });
      await this.props.getAllBroadCastsbyGroupID(this.props.location.state._id);
      this.setState({
        loadingTable: false,
      });
    }
  }

  //Refreshing Queries
  async refreshQueries() {
    this.setState({
      loadingTable: true,
    });
    await this.props.getAllGroups();
    this.setState({
      loadingTable: false,
    });
  }

  async componentDidUpdate(previousProps, previousState) {
    if (previousProps !== this.props) {
    }
  }

  //Send Template Button
  sendTemplate = () => {
    this.setState({
      replyQuery: "For any queries, kindly reach out to your HRPP",
      sendTemplateResource: !this.state.sendTemplateResource,
    });
  };

  //Reply to query
  changeQueryReply = (e) => {
    this.setState({ replyQuery: e.target.value });
  };

  //ok Click
  onOkClick = async (e) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    await this.props.getAllGroups(accessToken);
    this.setState({ modelOpen: false });
  };

  //Navigate to Message
  navigateToMessage = (text) => {
    console.log("text console",text)
    this.setState({ navigateToMessage: true, messageDetail: text });
  };
  render() {
    const resolvedColumns = [
      {
        title: "Query",
        dataIndex: "query",
        align: "center",
        key: "query",
      },
      {
        title: "Query By",
        dataIndex: "userName",
        key: "userName",
        width: "150px",
        align: "center",
        render: (text) => <a>{text}</a>,
      },
      {
        title: "Reply",
        dataIndex: "reply",
        key: "reply",
        align: "center",
        width: "250px",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text) => (
          <Tag icon={<CheckCircleOutlined />} color="success">
            success
          </Tag>
        ),
      },
    ];
    const pendingColumns = [
      {
        title: "Query",
        dataIndex: "query",
        key: "query",
      },
      {
        title: "Query By",
        dataIndex: "userName",
        key: "userName",
        render: (text) => <a>{text}</a>,
      },
      Table.EXPAND_COLUMN,
      {
        dataIndex: "replyToQuery",
        key: "replyToQuery",
        width: "1px",
      },

      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text) => (
          <Tag icon={<ExclamationCircleOutlined />} color="warning">
            {text}
          </Tag>
        ),
      },
    ];

    const userCol = [
      {
        title: "Message",
        dataIndex: "",
        align: "left",
        width: "25%",
        render: (text) => (
          <a
            className=""
            type="primary"
            onClick={(event) => this.navigateToMessage(text)}
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {text.message === "" ? (
              <Tooltip title={text.message}>
                <div className="text-cover">{text.fileName}</div>
              </Tooltip>
            ) : (
              <Tooltip title={text.message}>
                <div className="text-cover"> {text.message}</div>
              </Tooltip>
            )}
          </a>
        ),
      },
      {
        title: "Attachment",
        dataIndex: "media",
        align: "center",
        render: (text) => (text !== "" ? <p>Yes</p> : <p>No</p>),
      },
      {
        title: "No. of Recipients",
        dataIndex: "",
        align: "center",
        render: (text) => <p>{text.allrecipients[0]}</p>,
      },
      {
        title: "No. of Reactions",
        dataIndex: "",
        align: "center",
        render: (text) => <p>{text.allrecipients[1]}</p>,
      },
      {
        title: "Queries/Replies",
        dataIndex: "",
        align: "center",
        render: (text) => (
          <Query
            broadcastId={text._id}
            queryIsExist={text.query.length > 0}
            queryLength={
              text.query.filter((queries) => queries.status === "pending")
                .length
            }
          />
        ),
        sorter: (a, b) =>
          a.query.filter((queries) => queries.status === "pending").length -
          b.query.filter((queries) => queries.status === "pending").length,
        defaultSortOrder: "descend",
        sortDirections: ["descend"],
      },
    ];
    const items = [
      {
        key: "1",
        label: `Pending Queries`,
        children: (
          <div>
            <Table
              rowKey="_id"
              expandable={{
                expandedRowRender: (record) => (
                  <div>
                    Replying to <b>{record.userName}</b>
                    <br />
                    Query- <b>{record.query}</b>
                    <TextArea
                      disabled={this.state.sendTemplateResource}
                      name="textarea"
                      value={this.state.replyQuery}
                      placeholder="Reply to Query"
                      onChange={this.changeQueryReply}
                    />
                    <div className="sendReply">
                      <Button
                        type="primary"
                        onClick={() => {
                          this.sendReply(record);
                        }}
                      >
                        Send
                      </Button>
                      &nbsp; &nbsp; OR
                      <Button type="text" onClick={this.sendTemplate}>
                        <u>Send Template Response</u>
                      </Button>
                    </div>
                  </div>
                ),
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <Button
                      onClick={(e) => {
                        onExpand(record, e);
                      }}
                    >
                      Reply To Query
                    </Button>
                  ) : (
                    <Button
                      onClick={(e) => {
                        onExpand(record, e);
                        this.handleQueryDialogVisiblity(true);
                      }}
                    >
                      Reply To Query
                    </Button>
                  ),
              }}
              columns={pendingColumns}
              dataSource={this.state.pendingQueries}
            />
          </div>
        ),
      },
      {
        key: "2",
        label: `Resolved Queries`,
        children: (
          <div>
            <Table
              columns={resolvedColumns}
              dataSource={this.state.resolvedQueries}
            />
          </div>
        ),
      },
    ];
    return (
      <>
        <div className="container dashboard statistics-card">
          <Modal
            title="Queries From users"
            style={{
              top: 20,
            }}
            width={800}
            open={this.state.modelOpen}
            onOk={this.onOkClick}
            onCancel={() => this.setState({ modelOpen: false })}
          >
            <Tabs defaultActiveKey="1" items={items} />
          </Modal>

          {this.state.navigateToMessage ? (
            <Navigate
              to="/groups/customgroupbroadcast/viewbroadcastMessage"
              state={this.state.messageDetail}
            />
          ) : (
            ""
          )}

          <div className="row ">
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <BreadCrumbs location={this.props.location} />
              </Col>
              <Col span={1}></Col>
              <Col span={3}></Col>

              <Col span={6}></Col>
              <Col span={6}>
                <Row gutter={[16, 16]}>
                  <Col></Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <div className="mt-4">
          <div className="card">
            <div className="card-body p-5">
              <Table
                columns={userCol}
                dataSource={
                  // this.props.groups.filter(
                  //   (group) => group._id === this.props.location.state._id
                  // ).length
                  //   ? this.props.groups.filter(
                  //       (group) => group._id === this.props.location.state._id
                  //     )[0].broadcastMessages
                  //   : null
                  this.props.broadcasts
                }
                loading={this.state.loadingTable}
                rowKey="_id"
                align="left"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => (
  console.log("getAllBroadCastsbyGroupID",state),{
  errors: state.errors,
  groups: state.users.groups,
  broadcasts: state.users.broadcasts,
  sendBroadcastMsg: state.users.sendBroadCastMsgResponse,
});
export default connect(mapStateToProps, {
  sendReplyToQuery,
  getAllGroups,
  getAllBroadCastsbyGroupID,
  deleteUserFromGroup,
})(CustomGroupBroadcast);
