import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import userReducer from "./userReducer";
import eventReducer from "./eventReducer";
const rootReducer = combineReducers({
  errors: errorReducer,
  users: userReducer,
  events: eventReducer,
});

export default rootReducer;
