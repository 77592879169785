import React, { Component } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { getAllEvents } from "../../../actions/eventActions";
import {
  Popconfirm,
  Table,
  Tooltip,
  message,
  DatePicker,
  Input,
  Row,
  Col,
  Space,
  Button,
  Form,
  Modal,
} from "antd";
import CreateEvent from "./CreateEvent";
import moment from "moment";
import axios from "axios";
import {
  DeleteTwoTone,
  EditTwoTone,
  EyeTwoTone,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Navigate } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";

import { Auth } from "aws-amplify";

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      navigate: false,
      navigateToEvent: false,
      eventList: [],
      date: null,
      visible: false,
      loading: false,
      name: "",
      date: "",
      dataValue: "",
      newDate: "",
      selectedRow: "",
      createdBy: "",
    };
  }

  showModal = (data) => {
    this.setState({
      visible: true,
      dataValue: data,
      newDate: data.eventDate,
      selectedRow: data._id,
      createdBy: data.createdBy,
      name: data.name,
      date: data.eventDate,
    });
  };
  handleModalCancel = () => {
    this.setState({
      visible: false,
      date: null,
    });
  };

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    // this.props.getAllEvents(accessToken); //Get All
    this.setState({ loading: true });
    this.getEventList(accessToken);
  }

  //Get Event List
  getEventList = async (accessToken) => {
    let response = await axios.get(
      "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/event",
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    console.log("response after updated=========>", response);
    if (response.status === 200) {
      this.setState({ eventList: response.data.reverse() });
      this.setState({ loading: false });
    }
  };

  //Navigate to View Broadcast
  navigateToViewBroadcast = (event) => {
    this.setState({ event: event, navigate: true });
  };

  //Delete Event
  callOnDelete = async (id) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let eventId = id;
    let resp = await axios
      .post(
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/event`,
        { eventId },
        { headers: { Authorization: "Bearer " + accessToken } }
      )
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          message.success(`Event Deleted Successfully`);
        }
        this.getEventList(accessToken);
      })
      .catch((error) => {
        message.error(`Something went wrong`);
      });
  };

  //Cancel Call On Delete
  cancelCallOnDelete = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.getEventList(accessToken);
  };

  capitalize = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  };

  callSaveUpdateEvent = async (data) => {
    this.setState({ loading: true });
    let username = "";
    const user = await Auth.currentAuthenticatedUser();
    if (user && user.username) {
      username = user.username.includes("azuread_")
        ? user.username.split("azuread_")[1]
        : user.username;
    }
    var email = user.username;
    email = email.split("_");
    email = email[1].split("@");
    email = email[0].split(".");

    let name = `${this.capitalize(email[0])} ${this.capitalize(email[1])}`;

    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    const update = {
      eventId: this.state.selectedRow,
      name: this.state.name,
      createdBy: this.state.createdBy,
      eventDate: this.state.date,
    };
    let response = await axios
      .post(
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/event`,
        update,
        { headers: { Authorization: "Bearer" + accessToken } }
      )
      .then((response) => {
        if (response.status == 200) {
          message.success("Event Updated Successfully");
        }
        this.setState({ loading: false });
        this.getEventList(accessToken);
        this.handleModalCancel();
      })
      .catch((error) => {
        message.error(`Something went Wrong`);
      });
  };

  handleDateChange = (e, date) => {
    this.setState({ date: date });
  };

  createEventCallback = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.setState({ loading: true });
    this.getEventList(accessToken);
  };

  render() {
    const { visible, loading } = this.state;
    const disabledDate = (current) => {
      return current && current < dayjs().endOf("day").subtract(1, "day");
    };
    const userCol = [
      {
        title: "Event Title",
        dataIndex: "name",
        align: "left",
      },
      {
        title: "Event Date",
        dataIndex: "",
        align: "center",
        render: (data) => (
          <>
            <div>{moment(data.eventDate).format("YYYY-MM-DD")}</div>
          </>
        ),
      },
      {
        title: "Created By",
        dataIndex: "",
        align: "center",
        render: (text) => text.createdBy,
      },
      {
        title: "No. of Broadcasts",
        dataIndex: "",
        align: "center",
        render: (text) => text.broadcastMessages?.length,
      },
      {
        title: "Details",
        dataIndex: "",
        align: "center",
        render: (text) => (
          <Tooltip title="View Event">
            <EyeTwoTone
              twoToneColor="#5046e5"
              style={{ fontSize: "20px" }}
              onClick={() => this.navigateToViewBroadcast(text)}
            />
          </Tooltip>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        render: (id, data) => (
          <>
            <div>
              <Tooltip title="Update Title or Date">
                <EditTwoTone
                  style={{ color: "#5046e5" }}
                  className="icon-class"
                  onClick={() => {
                    this.showModal(data);
                  }}
                />
              </Tooltip>
              &nbsp;&nbsp;&nbsp;
              <Modal
                open={visible}
                onCancel={this.handleModalCancel}
                title="Update Event"
                style={{ top: 20 }}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                destroyOnClose={true}
                width={900}
                className="update-modal"
              >
                <Form
                  name="add-Group"
                  className="add-Group"
                  initialValues={{
                    remember: true,
                  }}
                >
                  <Row>
                    <Col>
                      <Form.Item name="name">
                        <Input
                          className="event_input"
                          placeholder="Event Name"
                          name="name"
                          defaultValue={this.state.dataValue.name}
                          onChange={this.handleNameChange}
                        />
                      </Form.Item>
                    </Col>
                    &nbsp; &nbsp;
                    <Col>
                      <Form.Item name="eventDate">
                        <Space direction="vertical">
                          <DatePicker
                            className="event_picker"
                            disabledDate={disabledDate}
                            name="eventDate"
                            defaultValue={moment(
                              this.state.newDate,
                              "YYYY-MM-DD"
                            )}
                            onChange={this.handleDateChange}
                          />
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item className="float-end">
                    <Button
                      className="close-modal me-3"
                      onClick={this.handleModalCancel}
                    >
                      Close
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ok-modal"
                      onClick={this.callSaveUpdateEvent}
                      loading={loading}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
              <Tooltip title="Delete Event">
                <Popconfirm
                  placement="left"
                  title="Are you sure, you want to delete the Event?"
                  onConfirm={() => this.callOnDelete(data._id)}
                  onCancel={() => this.cancelCallOnDelete()}
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                >
                  <DeleteTwoTone
                    twoToneColor="#d91d0f"
                    style={{
                      fontSize: "18px",
                    }}
                    size="small"
                    shape="circle"
                  />
                </Popconfirm>
              </Tooltip>
            </div>
          </>
        ),
      },
    ];

    return (
      <div className="container dashboard statistics-card">
        {this.state.navigate ? (
          <Navigate
            to="/events/viewbroadcast"
            replace={true}
            state={this.state.event}
          />
        ) : (
          ""
        )}

        <div className="row ">
          <div className="col-8">
            <h2 className="page-title">Events</h2>
            <BreadCrumbs location={this.props.location} />
          </div>
          <div className="col-4">
            <CreateEvent createEventCallback={this.createEventCallback} />
          </div>
        </div>
        <div className="mt-4">
          <div className="card">
            <div className="card-body p-5">
              <Table
                columns={userCol}
                dataSource={this.state.eventList}
                loading={this.state.loading}
                rowKey="_id"
                align="left"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  events: state.events.events,
});
export default connect(mapStateToProps, {
  getAllEvents,
})(Events);
