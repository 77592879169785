import React, { Component } from "react";
import { Layout, Switch } from "antd";
import Dashboard from "./dashboard/Dashboard";
import PropTypes from "prop-types";
import HeaderAdmin from "../layouts/HeaderAdmin";
import SiderMenu from "./SiderMenu";
import { withAuthenticator } from "@aws-amplify/ui-react";
import BroadcastComponent from "./BroadcastComponent";
import Register from "../auth/Register";
import moment from "moment";
import Groups from "./groups/Groups";
import Events from "./events/Events";
import Broadcast from "./broadcast/Broadcast";
import ViewBroadcastMessage from "./broadcast/ViewBroadcastMessage";
import CustomGroupBroadcast from "./groups/CustomGroupBroadcast";
import Schedule from "./schedule/Schedule";
import Analytics from "./analytics/Analytics";
import ScheduledBroadcast from "./schedule/ScheduledBroadcast";
import Feedback from "./feedback/Feedback";
import SidebarQuery from "./query/sidebarQuery";
import Conversation from "./conversation/Conversation";

const { Sider, Content, Footer } = Layout;

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    const Token = localStorage.getItem("token");
    let IsLoggedIn = true;
    if (Token === null) {
      IsLoggedIn = false;
    }
    this.state = {
      IsLoggedIn,
      collapsed: false,
      sideCollapsed: false,
      hideSwitch: false,
      viewToggleBtn: true,
    };
  }

  //Toggle Collapse
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
      hideSwitch: true,
      sideCollapsed: false,
    });
    if (this.state.sideCollapsed) {
      this.setState({ sideCollapsed: false });
    }
  };

  //Switch Change
  onSwitchChange = (checked) => {
    this.setState({ sideCollapsed: checked });
    if (!this.state.sideCollapsed) {
      this.setState({ viewToggleBtn: false });
    }
  };

  //Mouse Enter
  handleMouseEnter = () => {
    this.setState({ viewToggleBtn: true });
  };

  //Handle Mouse Leave
  handleMouseLeave = () => {
    this.setState({ viewToggleBtn: false });
  };

  renderPathName(pathname) {
    switch (pathname) {
      case "/register":
        return <Register location={this.props.location} />;
      case "/events/viewbroadcast/broadcast":
        return <BroadcastComponent location={this.props.location} />;
      case "/groups":
        return <Groups location={this.props.location} />;
      case "/events":
        return <Events location={this.props.location} />;
      case "/events/viewbroadcast":
        return <Broadcast location={this.props.location} />;

      case "/groups/customgroupbroadcast":
        return <CustomGroupBroadcast location={this.props.location} />;
      case "/groups/customgroupbroadcast/viewbroadcastMessage":
        return <ViewBroadcastMessage location={this.props.location} />;
      case "/scheduleBroadcast":
        return <Schedule location={this.props.location} />;
      case "/scheduledBroadcast":
        return <ScheduledBroadcast location={this.props.location} />;
      case "/analytics":
        return <Analytics location={this.props.location} />;
      case "/feedback":
        return <Feedback location={this.props.location} />;
      case "/query":
        return <SidebarQuery location={this.props.location} />;
      case "/conversation":
        return <Conversation location={this.props.location} />;
      default:
        return <Dashboard location={this.props.location} />;
    }
  }
  render() {
    const { pathname } = this.props.location;
    return (
      <>
        <Layout className="admin-dash">
          <Sider
            width={260}
            breakpoint="sm"
            collapsedWidth={this.state.sideCollapsed ? "80" : "0"}
            theme="light"
            trigger={null}
            collapsible
            collapsed={
              this.state.sideCollapsed
                ? this.state.sideCollapsed
                : this.state.collapsed
            }
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <div className={this.state.collapsed ? "display-switch" : ""}>
              <div
                className={
                  !this.state.sideCollapsed ? "toogle-off" : "toogle-on"
                }
              >
                <span
                  className={
                    this.state.sideCollapsed && !this.state.viewToggleBtn
                      ? "collapsed-switch-visible"
                      : ""
                  }
                >
                  <Switch
                    checked={this.state.sideCollapsed}
                    onChange={this.onSwitchChange}
                    size="small"
                  />
                </span>
              </div>
            </div>

            <SiderMenu
              history={this.props.history}
              user={this.props.user}
              collapsed={this.state.collapsed}
            ></SiderMenu>
          </Sider>
          <Layout className="site-layout">
            <HeaderAdmin
              toggle={this.toggle}
              collapsed={this.state.collapsed}
              user={this.props.user}
              signOut={this.props.signOut}
              location={this.props.location}
            />
            <Content
              style={{
                margin: "30px",

                minHeight: "100vh",
              }}
            >
              {this.renderPathName(pathname)}
            </Content>{" "}
            <Footer
              style={{
                textAlign: "center",
              }}
            >
              Employee Connect ©{moment().year()}
            </Footer>
          </Layout>
        </Layout>
      </>
    );
  }
}
AdminDashboard.propTypes = {
  location: PropTypes.object.isRequired,
};
export default withAuthenticator(AdminDashboard);
