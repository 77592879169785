import { EyeOutlined } from "@ant-design/icons";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Modal } from "antd";
import React, { Component } from "react";

export default class PreviewUploadedImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  //Show Modal
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  //Handle Modal Cancel
  handleModalCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    return (
      <>
        <button
          style={{ width: "50px" }}
          type="button"
          class="btn btn-outline-success btn-sm"
          onClick={this.showModal}
        >
          <EyeOutlined />
        </button>
        <Modal
          width={1200}
          open={visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="Preview"
          style={{ top: 30 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
        >
          {(this.props.fileType == "image/png" ||
            this.props.fileType == "image/jpeg") && (
            <img style={{ width: "100%" }} src={this.props.file} />
          )}
          {this.props.fileType == "application/pdf" && (
            <div
              style={{
                border: "1px solid rgba(0, 0, 0, 0.3)",
                height: "600px",
                overflow: "scroll",
              }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                <Viewer fileUrl={this.props.file} />
              </Worker>
            </div>
          )}
          {this.props.fileType == "video/mp4" && <p>Nothing to preview</p>}
        </Modal>
      </>
    );
  }
}
