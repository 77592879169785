import React, { Component } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import Papa from "papaparse";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Input,
  Select,
  Tooltip,
  Space,
  Upload,
  Table,
  Spin,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { message } from "antd";
import {
  getAllGroups,
  getAllUsers,
  addMembers,
  getBusinessGroups,
  getBusinessUnits,
  getLocations,
  setUsers,
} from "../../../actions/userAction";
import Highlighter from "react-highlight-words";
import { Auth } from "aws-amplify";

const { Option } = Select;

class AddMembers extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      visible: false,
      members: [],
      groupName: "",
      errors: {},
      businessGroupName: null,
      businessUnitName: null,
      locationCode: null,
      users: [],
      recipients: [],
      disableBG: false,
      disableBU: false,
      disableLoc: false,
      enableLoader: false,
      uploadMember: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSelectRow = this.onSelectRow.bind(this);
    this.onUserSearch = this.onUserSearch.bind(this);
  }

  //Show Modal
  showModal = () => {
    this.setState({
      visible: true,
      users: [],
      enableLoader: false,
    });
  };

  //Change Handler
  changeHandler = (event) => {
    Papa.parse(event, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        let keys = Object.keys(results.data[0]);
        if (
          keys.includes("businessUnit") &&
          keys.includes("cellPhone") &&
          keys.includes("name") &&
          keys.includes("email") &&
          keys.includes("empId") &&
          keys.includes("jobTitle") &&
          keys.includes("manager")
        ) {
          let validArray = [];
          results.data.map((user) => {
            if (
              user.name !== "" &&
              user.cellPhone !== "" &&
              user.email !== ""
            ) {
              validArray.push(user);
            }
          });

          this.setState({ recipients: validArray });
        } else {
        }
      },
    });
  };

  //COlumn Search
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            ?.toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  //Search in column
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  //Uploading Members
  uploadMembers = () => {
    this.setState({ uploadMember: !this.state.uploadMember, recipients: [] });
  };

  //Resetting search of columns
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  //handle Modal Cancel
  handleModalCancel = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.setState({
      users: [],
      recipients: [],
      groupName: "",
      locationCode: null,
      businessUnitName: null,
      businessGroupName: null,
      visible: false,
    });
    this.props.setUsers(accessToken);
  };

  //Get Details
  getDetails = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    await this.props.getBusinessGroups(accessToken); //Get Business Groups
    await this.props.getBusinessUnits(accessToken); //Get BUsiness Units
    await this.props.getLocations(accessToken); //Get Location

    if (this.props.locations.length > 0) {
      let userObj = {
        businessGroupName: this.state.businessGroupName,
        businessUnitName: this.state.businessUnitName,
        locationCode: this.state.locationCode,
      };
      await this.props.getAllUsers({}, accessToken); //Get All Users
    }
  };
  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.getDetails();
    this.props.getAllUsers(this.props.history, accessToken); //Get All Users
    this.props.getAllGroups(this.props.history, accessToken); //GEt All Groups
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }
  }

  //On Group Change
  onGroupChange = (event) => {
    this.setState({
      groupName: event,
    });
  };

  //On Business Group Change
  onBusinessGroupChange = (event) => {
    this.setState({
      disableBU: !this.state.disableBU,
      disableLoc: !this.state.disableLoc,
    });
    this.setState({
      businessGroupName: event ?? null,
      businessUnitName: null,
      locationCode: null,
    });
  };

  //On Business Unit Change
  onBusinessUnitChange = (event) => {
    this.setState({
      disableBG: !this.state.disableBG,
      disableLoc: !this.state.disableLoc,
    });
    this.setState({
      businessUnitName: event ?? null,
      businessGroupName: null,
      locationCode: null,
    });
  };

  //On Location Change
  onLocationChange = (event) => {
    this.setState({
      disableBG: !this.state.disableBG,
      disableBU: !this.state.disableBU,
    });
    this.setState({
      locationCode: event ?? null,
      businessUnitName: null,
      businessGroupName: null,
    });
  };

  //On Upload Change
  uploadChange = ({ fileList: newFileList }) => {
    if (newFileList.length === 0) {
      this.setState({ recipients: [] });
    }
  };

  //on change
  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  //On User Search
  async onUserSearch(event) {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.setState({
      enableLoader: true,
    });
    let userObjj = {
      businessGroupName: this.state.businessGroupName,
      businessUnitName: this.state.businessUnitName,
      locationCode: this.state.locationCode,
    };
    this.props.getAllUsers(userObjj, accessToken);
  }

  //On Save click
  onSave = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 1000);
    let userObj = {
      groupName: this.state.groupName,
      members: this.state.recipients,
    };
    this.props.addMembers(userObj, accessToken);
    this.setState({ recipients: [] });
  };

  //Select Row
  onSelectRow(arr) {
    this.setState({ noRecipientsSelectedError: false });
    this.setState({ recipients: arr });
  }

  render() {
    const { users } = this.props;
    const userCol = [
      {
        title: "Name",
        dataIndex: "name",
        align: "left",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Cell Phone",
        dataIndex: "cellPhone",
        align: "left",
        ...this.getColumnSearchProps("cellPhone"),
      },
      {
        title: "Email",
        dataIndex: "email",
        align: "left",
        ...this.getColumnSearchProps("email"),
      },
      {
        title: "Emp Id",
        dataIndex: "empId",
        align: "left",
        ...this.getColumnSearchProps("empId"),
      },
      {
        title: "Job Title",
        dataIndex: "jobTitle",
        align: "left",
      },
      {
        title: "BU",
        dataIndex: "businessUnit",
        align: "left",
        ...this.getColumnSearchProps("businessUnit"),
      },
    ];

    const rowSelection = {
      selectedRowKeys: this.state.selectedRows,
      onSelect: (record, selected) => {
        console.log(record);
      },
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
        this.onSelectRow(selectedRows);
      },
    };

    const { visible, loading } = this.state;
    var filteredUsers = this.props.users?.map((user) => {
      return (
        <Option
          key={[user._id, user.name, user.email, user.mobileNumber, user.empId]}
        >
          {user.name}
        </Option>
      );
    });
    const props = {
      name: "file",
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      headers: {
        authorization: "authorization-text",
      },
      onChange(info) {
        if (info.file.status !== "uploading") {
        }
        if (info.file.status === "done") {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
      <div>
        <Row>
          <Col>
            <div className="">
              <Button
                className="btn-admin btn-subCategory close-modal"
                type="primary"
                onClick={this.showModal}
              >
                Add Members
              </Button>
              <Modal
                width={1020}
                bodyStyle={{ overflowY: "auto", maxHeight: "600px" }}
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleModalCancel}
                title="Add Members"
                style={{ top: 20 }}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                destroyOnClose={true}
              >
                <div style={{ margin: "0px 0 -14px 0" }}>
                  <Button
                    className="btn-admin btn-subCategory float-end"
                    type="primary"
                    onClick={this.uploadMembers}
                  >
                    {this.state.uploadMember
                      ? "Select Members From Table"
                      : "Add Members From File"}
                  </Button>
                </div>

                <Form
                  ref={this.formRef}
                  name="add-Members"
                  className="add-Members"
                  onFinish={this.onSave}
                >
                  <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="groupName"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please Select Group Name you want to add members",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              ?.toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          name="groupName"
                          placeholder="Select a group to add members"
                          id="groupName"
                          onChange={this.onGroupChange}
                          value={this.state.groupName}
                        >
                          {this.props.groups.map((group) => (
                            <Option value={group.name}>{group.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {!this.state.uploadMember && (
                    <>
                      <Row gutter={[16, 16]}>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                          <div className="d-flex flex-row">
                            <Form.Item name="businessGroupName">
                              <Select
                                style={{ width: 250 }}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    ?.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    ?.toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                name="businessGroupName"
                                placeholder="Select Business Group"
                                id="businessGroupName"
                                onChange={this.onBusinessGroupChange}
                                value={this.state.businessGroupName}
                                disabled={this.state.disableBG}
                              >
                                {this.props.businessGroups?.map((group) => (
                                  <Option value={group.businessGroup}>
                                    {group.businessGroup}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                          <div className="d-flex flex-row">
                            <Form.Item name="businessUnitName">
                              <Select
                                style={{ width: 250 }}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    ?.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    ?.toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                name="businessUnitName"
                                placeholder="Select Business Unit"
                                id="businessUnitName"
                                onChange={this.onBusinessUnitChange}
                                value={this.state.businessUnitName}
                                disabled={this.state.disableBU}
                              >
                                {this.props.businessUnits?.map((group) => (
                                  <Option value={group.businessUnit}>
                                    {group.businessUnit}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={21} sm={21} md={21} lg={7} xl={7}>
                          <div className="d-flex flex-row">
                            <Form.Item name="locationCode">
                              <Select
                                style={{ width: 250 }}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    ?.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    ?.toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                name="locationCode"
                                placeholder="Select Location"
                                id="locationCode"
                                onChange={this.onLocationChange}
                                value={this.state.locationCode}
                                disabled={this.state.disableLoc}
                              >
                                {this.props.locations?.map((location) => (
                                  <Option key={location.locationCode}>
                                    {location.locationName}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                          <Form.Item>
                            <Tooltip title="search">
                              <Button
                                type="primary"
                                shape="circle"
                                icon={<SearchOutlined />}
                                onClick={this.onUserSearch}
                              />
                            </Tooltip>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]} className="title-row">
                        <Col xl={24}>
                          {users.length ? (
                            <Table
                              rowSelection={{
                                ...rowSelection,
                              }}
                              columns={userCol}
                              dataSource={users}
                              rowKey="empId"
                              scroll={{
                                y: 325,
                              }}
                            />
                          ) : (
                            <div
                              className=""
                              style={{
                                margin: "20px 0",
                                marginBottom: " 20px",
                                padding: "30px 50px",
                                textAlign: "center",
                                borderRadius: "4px",
                              }}
                            >
                              {this.state.enableLoader ? <Spin /> : ""}
                            </div>
                          )}
                        </Col>
                      </Row>{" "}
                    </>
                  )}
                  {this.state.uploadMember && (
                    <>
                      <Upload
                        accept=".csv"
                        {...props}
                        multiple="false"
                        maxCount={1}
                        beforeUpload={(file) => {
                          const reader = new FileReader();
                          reader.onload = (e) => {
                            this.changeHandler(e.target.result);
                          };
                          reader.readAsText(file);
                          return false;
                        }}
                        onChange={this.uploadChange}
                      >
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                        {"  "}
                        <>Accept only .csv files</>
                      </Upload>
                      <Table
                        columns={userCol}
                        dataSource={this.state.recipients}
                        rowKey="empId"
                      />
                    </>
                  )}
                  {this.state.recipients.length ? (
                    <Form.Item className="float-end">
                      <Button
                        className="close-modal me-3"
                        onClick={this.handleModalCancel}
                      >
                        Close
                      </Button>
                      <Button
                        type="primary"
                        htmlType=""
                        className="ok-modal"
                        loading={loading}
                        onClick={this.onSave}
                        disabled={this.state.groupName === "" ? true : false}
                      >
                        Save
                      </Button>
                    </Form.Item>
                  ) : (
                    ""
                  )}
                </Form>
              </Modal>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  businessGroups: state.users.businessGroups,
  businessUnits: state.users.businessUnits,
  locations: state.users.locations,
  errors: state.errors,
  users: state.users.users,
  groups: state.users.groups,
});
export default connect(mapStateToProps, {
  getBusinessGroups,
  getBusinessUnits,
  getLocations,
  getAllUsers,
  getAllGroups,
  addMembers,
  setUsers,
})(AddMembers);
