import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, Form, Input } from "antd";
import { addGroups } from "../../../actions/userAction";
import { Auth } from "aws-amplify";

class AddGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      name: "",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  //Cancel Modal
  handleModalCancel = () => {
    this.setState({ visible: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }
  }

  //On Change Group Name
  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async onSubmit(event) {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 1000);

    let groupObj = {
      name: this.state.name,
    };
    this.props.addGroups(groupObj, accessToken);

    setTimeout(() => {
      this.setState({ fileList: [] });
    }, 1000);
  }

  render() {
    const { visible, loading } = this.state;

    return (
      <Row>
        <Col>
          <div className="">
            <Button
              className="add-group"
              type="primary"
              onClick={this.showModal}
            >
              Add Group
            </Button>
            <Modal
              visible={visible}
              onOk={this.handleOk}
              onCancel={this.handleModalCancel}
              title="Add Group"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <Form
                name="add-Group"
                className="add-Group"
                onFinish={this.onSubmit}
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Group Name!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder="Group Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                  />
                </Form.Item>

                <Form.Item className="float-end">
                  <Button
                    className="close-modal me-3"
                    onClick={this.handleModalCancel}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ok-modal"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
    );
  }
}
export default connect(null, { addGroups })(AddGroup);
