import {
  ADD_GROUPS,
  ADD_MEMBERS,
  DELETE_MEMBER,
  DELETE_SCHEDULE,
  GET_ALL_BUSINESS_GROUPS,
  GET_ALL_BUSINESS_UNITS,
  GET_ALL_GROUP_BROADCASTS,
  GET_BROADCAST_DETAILS,
  GET_ALL_GROUPS,
  GET_ALL_LOCATIONS,
  GET_ALL_USERS,
  GET_DASHBOARD_DATA,
  GET_VALIDATION,
  SCHEDULE_BROADCAST_MESSAGE,
  SEND_BROADCAST_MESSAGE,
  UPDATE_SCHEDULE,
} from "../actions/type";

const initialState = {
  user: {},
  group: {},
  broadcasts:[],
  broadcastDetail:[],
  businessGroup: {},
  businessUnit: {},
  location: {},
  users: [],
  groups: [],
  businessGroups: [],
  businessUnits: [],
  locations: [],
  getValidation: false,
  dashboardData: [],
  sendBroadCastMsgResponse: "",
  scheduleBroadcastMessageResponse: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_GROUPS:
      return {
        ...state,
        groups: [action.payload, ...state.groups],
      };
    case ADD_MEMBERS:
      return {
        ...state,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case GET_ALL_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case GET_ALL_BUSINESS_GROUPS:
      return {
        ...state,
        businessGroups: action.payload,
      };
    case GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
      };
    case GET_ALL_BUSINESS_UNITS:
      return {
        ...state,
        businessUnits: action.payload,
      };
    case GET_ALL_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case "Set_users":
      return {
        ...state,
        users: action.payload,
      };
    case SEND_BROADCAST_MESSAGE:
      return {
        ...state,
        sendBroadCastMsgResponse: action.payload,
      };
    case "set_Breadcastmsg":
      return {
        ...state,
        sendBroadCastMsgResponse: action.payload,
      };
    case SCHEDULE_BROADCAST_MESSAGE:
      return {
        ...state,
        scheduleBroadcastMessageResponse: action.payload,
      };
    case "set_BroadcastSceduleMsg":
      return {
        ...state,
        scheduleBroadcastMessageResponse: action.payload,
      };
    case UPDATE_SCHEDULE:
      return {
        ...state,
        scheduleBroadcastMessageResponse: action.payload,
      };
    case DELETE_SCHEDULE:
      return {
        ...state,
        scheduleBroadcastMessageResponse: action.payload,
      };
    case GET_VALIDATION:
      return {
        ...state,
        getValidation: action.data,
      };
    case GET_ALL_GROUP_BROADCASTS:
      return {
        ...state,
        broadcasts: action.payload,
      };
    case GET_BROADCAST_DETAILS:
      return {
        ...state,
        broadcastDetail: action.payload,
      };
    case DELETE_MEMBER:
      var updatedUsers = [];
      updatedUsers = state.groups.filter((group) => {
        if (group._id == action.payload._id) {
          group.members = group.members.filter((user) => {
            if (user.empId != action.payload.members[0].empId) return user;
          });
          return group;
        } else {
          return group;
        }
      });

      return {
        ...state,
        groups: updatedUsers,
      };

    default:
      return state;
  }
}
