import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getBusinessGroups,
  getBusinessUnits,
  getLocations,
  getAllUsers,
} from "../../actions/userAction";
import { Button, Col, Form, Row, Select, Tooltip } from "antd";
import BroadcastLists from "./broadcast/BroadcastLists";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import BreadCrumbs from "../BreadCrumbs";
import { Auth } from "aws-amplify";
import { NavLink, Navigate } from "react-router-dom";
const { Option } = Select;

class BroadcastComponent extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      message: "",
      businessGroupName: null,
      businessUnitName: null,
      locationCode: null,
      errors: {},
      eventId: "",
    };
  }

  //Get Details
  getDetails = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    await this.props.getBusinessGroups(accessToken); //Get Business Groups
    await this.props.getBusinessUnits(accessToken); //Get Business Units
    await this.props.getLocations(accessToken); //Get Locations
    await this.setState({ locationCode: 102 });
    this.formRef.current?.setFieldsValue({
      locationCode: "Indore-Crystal IT Park-DC",
    });
    if (this.props.locations?.length > 0) {
      let userObj = {
        businessGroupName: this.state.businessGroupName,
        businessUnitName: this.state.businessUnitName,
        locationCode: 102,
      };
      await this.props.getAllUsers(userObj); //Get All Users
    }
  };
  componentDidMount() {
    this.getDetails();
  }

  //Submit Form
  onSubmit = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let userObj = {
      businessGroupName: this.state.businessGroupName,
      businessUnitName: this.state.businessUnitName,
      locationCode: this.state.locationCode,
    };
    this.props.getAllUsers(userObj, accessToken);
  };

  //Group Change
  onGroupChange = (event) => {
    this.setState({
      businessGroupName: event ?? null,
    });
  };

  //Business Unit Change
  onBusinessUnitChange = (event) => {
    this.setState({
      businessUnitName: event ?? null,
    });
  };

  //Location Change
  onLocationChange = (event) => {
    this.setState({
      locationCode: event ?? null,
    });
  };

  render() {
    return (
      <>
        <Row>
          <div>
            {" "}
            <h2 className="page-title">Broadcast</h2>
            <BreadCrumbs location={this.props.location} />
          </div>
        </Row>
        <div className="container dashboard statistics-card">
          <Form
            ref={this.formRef}
            name="add-Members"
            className="add-Members"
            onFinish={this.onSubmit}
            initialValues={{
              remember: true,
            }}
          >
            <Row className="title-row">
              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="d-flex flex-row">
                  <Form.Item name="businessGroupName">
                    <Select
                      style={{ width: 250 }}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          ?.toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      name="businessGroupName"
                      placeholder="Select Business Group"
                      id="businessGroupName"
                      onChange={this.onGroupChange}
                      value={this.state?.businessGroupName}
                    >
                      {this.props?.businessGroups?.map((group) => (
                        <Option value={group.businessGroup}>
                          {group.businessGroup}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="d-flex flex-row">
                  <Form.Item name="businessUnitName">
                    <Select
                      style={{ width: 250 }}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          ?.toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      name="businessUnitName"
                      placeholder="Select Business Unit"
                      id="businessUnitName"
                      onChange={this.onBusinessUnitChange}
                      value={this.state.businessUnitName}
                    >
                      {this.props?.businessUnits?.map((group) => (
                        <Option value={group.businessUnit}>
                          {group.businessUnit}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="d-flex flex-row">
                  <Form.Item name="locationCode">
                    <Select
                      style={{ width: 250 }}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          ?.toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      name="locationCode"
                      placeholder="Select Location"
                      id="locationCode"
                      onChange={this.onLocationChange}
                      value={this.state.locationCode}
                    >
                      {this.props.locations?.map((location) => (
                        <Option key={location.locationCode}>
                          {location.locationName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                <Form.Item>
                  <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    htmlType="submit"
                  >
                    Search
                  </Button>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                <NavLink to={"/scheduleBroadcast"}>
                  <Button type="primary">Schedule Broadcast</Button>
                </NavLink>
              </Col>
            </Row>
          </Form>

          <BroadcastLists
            users={this.props.users}
            eventId={this.props.location.state}
          />
        </div>
      </>
    );
  }
}
BroadcastComponent.propTypes = {
  location: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  errors: state.errors,
  businessGroups: state.users.businessGroups,
  businessUnits: state.users.businessUnits,
  locations: state.users.locations,
  users: state.users.users,
  sendBroadcastMsg: state.users.sendBroadCastMsgResponse,
});
export default connect(mapStateToProps, {
  getBusinessGroups,
  getBusinessUnits,
  getLocations,
  getAllUsers,
})(BroadcastComponent);
