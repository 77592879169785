import React from "react";
import moment from "moment";

const ChatBubble = ({ message }) => {
  return (
    <div
      className={`chat-bubble ${
        message.message.IsSender ? "sender" : "receiver"
      }`}
    >
      <div className="message-text">{message.message.message}</div>
      <div className="message-timestamp">
        {moment(message.message.timeStamp).format("DD-MM-YY LT")}
      </div>
    </div>
  );
};

const ChatBody = ({ messages }) => {
  return (
    <>
      <div className="chat-body">
        {messages.map((message, index) => (
          <ChatBubble key={index} message={{ message }} />
        ))}
      </div>
    </>
  );
};

export default ChatBody;
