import React, { Component } from "react";
import { Menu, Drawer } from "antd";
import {
  LayoutFilled,
  UserAddOutlined,
  MenuUnfoldOutlined,
  TeamOutlined,
  CalendarOutlined,
  ScheduleOutlined,
  ContainerOutlined,
  ProfileOutlined,
  ExceptionOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import logo from "../../assests/img/LOGO.png";
import name from "../../assests/img/name.png";
export default class SiderDrawerPhone extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, placement: "left" };
  }

  //Show Drawer
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  //On Close Drawer
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <span>
        <MenuUnfoldOutlined
          onClick={this.showDrawer}
          className="trigger-phone"
        />
        <Drawer
          placement={this.state.placement}
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          key={this.state.placement}
        >
          <div className="brand-name">
            <div className="brand-logo">
              <div></div>
              <span class="app-name" mode="full">
                <div style={{ margin: "15px 0px 20px 30px" }}>
                  Employee Connect
                </div>
              </span>
            </div>
          </div>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
          >
            <Menu.Item
              key="/dashboard"
              icon={<LayoutFilled />}
              style={{ fontSize: "1.5rem" }}
            >
              <NavLink className="sider-links mt-3" to="/dashboard">
                Dashboard
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key="/analytics"
              icon={<ContainerOutlined />}
              style={{ fontSize: "1.5rem" }}
            >
              <NavLink className="sider-links" to="/analytics">
                Analytics
              </NavLink>
            </Menu.Item>
            <p class="menu-subhead" mode="full">
              OPERATIONS
            </p>

            <Menu.Item
              key="/events"
              icon={<CalendarOutlined />}
              style={{ fontSize: "1.5rem" }}
              title="Events"
            >
              <NavLink className="sider-links " to="/events">
                Events
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key="/groups"
              icon={<TeamOutlined />}
              title="Group Managment"
              style={{ fontSize: "1.5rem" }}
            >
              <NavLink className="sider-links " to="/groups">
                Group Management
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key="/scheduleBroadcast"
              icon={<ScheduleOutlined />}
              style={{ fontSize: "1.5rem" }}
              title="Schedule Events"
            >
              <NavLink className="sider-links " to="/scheduleBroadcast">
                Schedule Broadcasts
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key="/feedback"
              icon={<ProfileOutlined />}
              style={{ fontSize: "1.5rem" }}
              title="Feedbacks"
            >
              <NavLink className="sider-links " to="/feedback">
                Feedbacks
              </NavLink>
            </Menu.Item>

            <Menu.Item
              key="/query"
              icon={<ExceptionOutlined />}
              style={{ fontSize: "1.5rem" }}
              title="Queries"
            >
              <NavLink className="sider-links " to="/query">
                Queries
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key="/conversation"
              icon={<WhatsAppOutlined />}
              style={{ fontSize: "1.5rem" }}
              title="Conversation"
            >
              <NavLink className="sider-links " to="/conversation">
                Conversation
              </NavLink>
            </Menu.Item>

            {/* <Menu.Item
              key="/register"
              icon={<UserAddOutlined />}
              style={{ fontSize: "1.5rem" }}
              title="Register"
            >
              <NavLink className="sider-links " to="/register">
                Register
              </NavLink>
            </Menu.Item> */}
          </Menu>
        </Drawer>
      </span>
    );
  }
}
