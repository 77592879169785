import React, { Component } from "react";
import { Col, DatePicker, Button, Tooltip } from "antd";
import axios from "axios";
import { DownloadOutlined } from "@ant-design/icons";
import {
  CCardBody,
  CCardSubtitle,
  CCardTitle,
  CCol,
  CRow,
} from "@coreui/react";
import { CChartBar } from "@coreui/react-chartjs";
import { getStyle } from "@coreui/utils";
import { CSVLink } from "react-csv";
import { Auth } from "aws-amplify";
export default class BroadcastGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      loadingBroadcast: true,
      apiData: [],
      jan: null,
      feb: null,
      march: null,
      april: null,
      may: null,
      june: null,
      july: null,
      aug: null,
      sept: null,
      oct: null,
      nov: null,
      dec: null,
      Year: null,
    };
  }

  //Date Change
  onDateChange = async (selectedYear) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let selected = new Date(selectedYear);
    this.setState({ Year: selected.getFullYear() });
    //Fetching data of Graph
    axios
      .get(
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/sendbroadcastmsg/monthly-broadcasts?year=${selected.getFullYear()}`,

        { headers: { Authorization: "Bearer " + accessToken } }
      )
      .then((response) => {
        this.setState({ apiData: response.data });
        this.setState({ jan: response.data[0] });
        this.setState({ feb: response.data[1] });
        this.setState({ march: response.data[2] });
        this.setState({ april: response.data[3] });
        this.setState({ may: response.data[4] });
        this.setState({ june: response.data[5] });
        this.setState({ july: response.data[6] });
        this.setState({ aug: response.data[7] });
        this.setState({ sept: response.data[8] });
        this.setState({ oct: response.data[9] });
        this.setState({ nov: response.data[10] });
        this.setState({ dec: response.data[11] });
      });
  };

  render() {
    const headers = [
      { label: "Year", key: "year" },
      { label: "jan", key: "jan" },
      { label: "feb", key: "feb" },
      { label: "march", key: "march" },
      { label: "april", key: "april" },
      { label: "may", key: "may" },
      { label: "june", key: "june" },
      { label: "july", key: "july" },
      { label: "aug", key: "aug" },
      { label: "sept", key: "sept" },
      { label: "oct", key: "oct" },
      { label: "nov", key: "nov" },
      { label: "dec", key: "dec" },
    ];

    const data = [
      {
        year: this.state.Year,
        jan: this.state.jan,
        feb: this.state.feb,
        march: this.state.march,
        april: this.state.april,
        may: this.state.may,
        june: this.state.june,
        july: this.state.july,
        aug: this.state.aug,
        sept: this.state.sept,
        oct: this.state.oct,
        nov: this.state.nov,
        dec: this.state.dec,
      },
    ];
    return (
      <>
        <Col span={24} className="dashboard-new">
          <CCardBody className="p-4-bargraph">
            <CCardTitle className="fs-4 fw-semibold">
              <CRow xl={4}>
                <CCol xl={12}>
                  <CSVLink
                    data={data}
                    headers={headers}
                    className="float-end btn-dwn"
                    filename={"totalbroadcast-report.csv"}
                  >
                    <Tooltip title="Export to csv">
                      <div className="download-btn float-end">
                        <Button
                          size="large"
                          type="text"
                          disabled={
                            this.state.Year === null || this.state.Year === 1970
                          }
                        >
                          <DownloadOutlined />
                        </Button>
                      </div>
                    </Tooltip>
                  </CSVLink>
                </CCol>
                <CCol xl={6}>Broadcast Messages</CCol>
                <CCol xl={6}>
                  <DatePicker
                    style={{ width: "100% !important" }}
                    picker="year"
                    onChange={this.onDateChange}
                  />
                </CCol>
              </CRow>
            </CCardTitle>
            <CCardSubtitle className="fw-normal text-disabled">
              January 01, {this.state.Year} - December 31, {this.state.Year}{" "}
            </CCardSubtitle>
            <CChartBar
              data={{
                labels: [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ],
                datasets: [
                  {
                    label: "Users",
                    backgroundColor: getStyle("--cui-primary"),
                    borderRadius: 6,
                    borderSkipped: false,
                    data: [
                      this.state.jan,
                      this.state.feb,
                      this.state.march,
                      this.state.april,
                      this.state.may,
                      this.state.june,
                      this.state.july,
                      this.state.aug,
                      this.state.sept,
                      this.state.oct,
                      this.state.nov,
                      this.state.dec,
                    ],
                    barPercentage: 0.6,
                    categoryPercentage: 0.5,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                      drawBorder: false,
                      drawTicks: false,
                    },
                    ticks: {
                      color: "#5046e5",
                      font: {
                        size: 14,
                      },
                      padding: 16,
                    },
                  },
                  y: {
                    grid: {
                      drawBorder: false,
                      borderDash: [2, 4],
                    },
                    gridLines: {
                      borderDash: [8, 4],
                      color: "#348632",
                    },
                    ticks: {
                      beginAtZero: true,
                      color: getStyle("--cui-text-disabled"),
                      font: {
                        size: 14,
                      },
                      maxTicksLimit: 5,
                      padding: 16,
                      stepSize: Math.ceil(10 / 5),
                    },
                  },
                },
              }}
              style={{
                height: "300px",
                marginTop: "40px",
              }}
            />{" "}
          </CCardBody>{" "}
        </Col>
      </>
    );
  }
}
