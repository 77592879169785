import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllEvents, getAllSchedules } from "../../../actions/eventActions";
import {
  getBusinessGroups,
  getBusinessUnits,
  getLocations,
  getAllUsers,
  deleteSchedule,
  getAllGroups,
} from "../../../actions/userAction";
import { saveAs } from "file-saver";
import {
  Button,
  Col,
  Row,
  Input,
  Tooltip,
  Space,
  Table,
  Tabs,
  Descriptions,
  Popconfirm,
} from "antd";
import {
  SearchOutlined,
  DownloadOutlined,
  DeleteOutlined,
  EditTwoTone,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";
import { Navigate } from "react-router-dom";
import Paragraph from "antd/lib/typography/Paragraph";
import axios from "axios";
import moment from "moment";
import BreadCrumbs from "../../BreadCrumbs";
import { Auth } from "aws-amplify";

class ScheduledEventsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      editSchedule: false,
      searchText: "",
      searchedColumn: "",
      message: "",
      businessGroupName: null,
      businessUnitName: null,
      locationCode: null,
      eventName: null,
      scheduleDate: null,
      scheduleTime: null,
      errors: {},
      editableData: null,
      eventId: "",
      moreMessage: true,
    };
  }

  //Column Search
  getColumnSearchProps = (dataIndex, fieldName) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
          backgroundColor: "white",
          margin: "-52px 0px 0px -51px",
        }}
      >
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${fieldName}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            ?.toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },

    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  //Search Column
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  //Reset Searched Columns
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  cronToDate(cron) {
    let splited = cron.split(" ");
    let month, year, minute, hours, day, date;
    month = splited[3];
    year = splited[5];
    minute = splited[0];
    hours = splited[1];
    day = splited[2];
    date = day + "-" + month + "-" + year + "  " + hours + ":" + minute;
    return date;
  }

  //Get Details
  getDetails = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    await this.props.getBusinessGroups(accessToken); //Get Business Groups
    await this.props.getBusinessUnits(accessToken); // Get Business Unit
    await this.props.getLocations(accessToken); //Get Location
    await this.setState({ locationCode: 102 });
    // this.formRef.current.setFieldsValue({
    //   locationCode: "Indore-Crystal IT Park-DC",
    // });
    if (this.props.locations.length > 0) {
      let userObj = {
        businessGroupName: this.state.businessGroupName,
        businessUnitName: this.state.businessUnitName,
        locationCode: 102,
      };
      await this.props.getAllUsers(userObj, accessToken); //Get All Users
    }
  };
  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.props.getAllSchedules(accessToken);
    this.props.getAllEvents(accessToken);
    this.props.getAllGroups(accessToken);
    this.getDetails();
  }

  //Date Change
  onDateChange = (selectedDate) => {
    if (!!selectedDate) {
      const selectDate = new Date(selectedDate);
      const arrangeDate = `${selectDate.toLocaleString("default", {
        month: "short",
      })} ${selectDate.getDate()} ${selectDate.getFullYear()}`;
      this.setState({ scheduleDate: arrangeDate });
    }
  };

  //Time Change
  onTimeChange = (selecttime, timeString) => {
    const selectTime = new Date(selecttime);
    const arrangeTime = `${selectTime.getHours()}:${selectTime.getMinutes()}`;
    this.setState({ scheduleTime: arrangeTime });
  };

  //Form Submit
  onSubmit = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let userObj = {
      businessGroupName: this.state.businessGroupName,
      businessUnitName: this.state.businessUnitName,
      locationCode: this.state.locationCode,
    };
    this.props.getAllUsers(userObj, accessToken);
  };

  //SHow Modal
  showModal = (data) => {
    this.setState({ editableData: data, isModalOpen: true });
  };

  //Handle OK
  handleOk = () => {
    this.setState({ isModalOpen: false });
  };

  //Handlr Cancel
  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };

  //Group Change
  onGroupChange = (event) => {
    this.setState({
      businessGroupName: event ?? null,
    });
  };

  //Business Unit Change
  onBusinessUnitChange = (event) => {
    this.setState({
      businessUnitName: event ?? null,
    });
  };

  //Event Change
  onEventChange = (event) => {
    this.setState({
      eventName: event ?? null,
    });
  };

  //Download Image
  downloadImage = async (key) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let response = await axios.get(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/presignedurl?key=${key}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    saveAs(response.data.url); // Put your image url here.
  };

  //On Location Change
  onLocationChange = (event) => {
    this.setState({
      locationCode: event ?? null,
    });
  };

  //Delete Scheduled Broadcast
  deleteSchedule = async (scheduleId) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let reqObj = {
      scheduleId: scheduleId,
    };
    await this.props.deleteSchedule(reqObj, accessToken);
    await this.props.getAllSchedules(accessToken);
  };

  //Update Broadcast
  updateBroadcast = (data) => {
    let type = null;
    if (data.data.eventId === undefined) {
      type = "group";
    }
    if (data.data.groupId === undefined) {
      type = "event";
    }
    let date = new Date(this.cronToDateObject(data.data.scheduleDateTime));
    let updateData = {
      broadcastId: data.broadcastId,
      scheduleId: data._id,
      broadcastType: type,
      eventName: data.data.eventId,
      selectDateAndTime: date,
      broadcastMessage: data.data.broadcastMessage,
      recipients: data.data.recipients,
    };
    this.setState({ editableData: updateData, editSchedule: true });
  };

  //Select Row
  onSelectRow(arr) {
    this.setState({ noRecipientsSelectedError: false });
    this.setState({ recipients: arr });
  }

  //Select Group Row
  onSelectGroupRow(arr) {
    this.setState({ noRecipientsSelectedError: false });
    this.setState({ recipients: arr });
  }
  cronToDateObject(cron) {
    let cronDate = new Date();
    let cronDateAry = cron.split(" ");
    cronDate.setUTCMinutes(cronDateAry[0]);
    cronDate.setUTCHours(cronDateAry[1]);
    cronDate.setUTCDate(cronDateAry[2]);
    cronDate.setUTCMonth(cronDateAry[3] - 1);
    cronDate.setUTCFullYear(cronDateAry[5]);
    return cronDate;
  }
  cronToDate(cron) {
    let cronDate = new Date();
    let cronDateAry = cron.split(" ");
    cronDate.setUTCMinutes(cronDateAry[0]);
    cronDate.setUTCHours(cronDateAry[1]);
    cronDate.setUTCDate(cronDateAry[2]);
    cronDate.setUTCMonth(cronDateAry[3] - 1);
    cronDate.setUTCFullYear(cronDateAry[5]);
    return moment(cronDate).format("MMMM Do YYYY, h:mm");
  }
  render() {
    const columns = [
      {
        title: "Message ",
        dataIndex: "",
        key: "message",
        width: "20%",
        render: (text) => (
          <Tooltip title={text.data?.broadcastMessage}>
            <div className="schedule_event">
              {text.data?.broadcastMessage === ""
                ? text.data?.fileName
                : text.data?.broadcastMessage}
            </div>
          </Tooltip>
        ),
      },
      {
        title: "Type(Event/Group)",
        dataIndex: "",
        key: "type",
        width: "20%",
        render: (text) => <>{text.data.eventId !== null ? "Event" : "Group"}</>,
      },
      {
        title: "Name(Event/Group)",
        dataIndex: "",
        key: "typeName",
        width: "20%",
        ...this.getColumnSearchProps("event", "Event"),

        render: (text) => (
          <>
            {text.data.eventId !== null && text.data.eventId !== undefined
              ? this.props.events?.find(
                  (event) => event?._id === text.data?.eventId
                )?.name
              : text.data.groupId !== null && text.data.groupId !== undefined
              ? this.props.groups?.find(
                  (group) => group._id === text.data.groupId
                )?.name
              : ""}
          </>
        ),
      },
      {
        title: "Scheduled Date & Time",
        key: "scheduledatetime",
        ...this.getColumnSearchProps(
          "scheduledatetime",
          "Scheduled Date & Time"
        ),
        render: (text) => <p>{this.cronToDate(text.data?.scheduleDateTime)}</p>,
      },

      {
        title: "No. of Recipients",
        dataIndex: "",
        key: "recipients",
        align: "center",
        render: (text) => <p>{text.data?.recipients.length}</p>,
      },
      {
        title: "Action",
        dataIndex: "",
        key: "action",
        width: "15%",
        align: "center",
        render: (text) => (
          <>
            <EditTwoTone
              twoToneColor="#5046e5"
              style={{ fontSize: "20px" }}
              onClick={() => {
                this.updateBroadcast(text);
              }}
            />
            <Popconfirm
              title="Delete the Schedule"
              description="Are you sure to delete this Schedule?"
              okText="Yes"
              onConfirm={() => {
                this.deleteSchedule(text._id);
              }}
              cancelText="No"
            >
              &nbsp; &nbsp;
              <DeleteOutlined
                twoToneColor="#5046e5"
                style={{ color: "red", fontSize: "20px" }}
                onClick={() => {}}
              />
            </Popconfirm>
            &nbsp;
          </>
        ),
      },
    ];
    const completedColumns = [
      {
        title: "Message ",
        key: "message",
        width: "20%",
        render: (text) => (
          <Tooltip title={text.data?.broadcastMessage}>
            <div className="schedule_event">
              {" "}
              {text.data?.broadcastMessage === ""
                ? text.data?.fileName
                : text.data?.broadcastMessage}
            </div>
          </Tooltip>
        ),
      },
      {
        title: "Type(Event/Group)",
        key: "type",
        width: "20%",
        render: (text) => <>{text.data.eventId !== null ? "Event" : "Group"}</>,
      },
      {
        title: "Name(Event/Group)",
        key: "typeName",
        width: "20%",
        ...this.getColumnSearchProps("event", "Event"),
        render: (text) => (
          <>
            {text.data.eventId !== null && text.data.eventId !== undefined
              ? this.props.events?.find(
                  (event) => event._id === text.data?.eventId
                )?.name
              : text.data.groupId !== null && text.data.groupId !== undefined
              ? this.props.groups?.find(
                  (group) => group._id === text.data?.groupId
                ).name
              : ""}
          </>
        ),
      },

      {
        title: "Scheduled Date & Time",
        key: "scheduledatetime",
        ...this.getColumnSearchProps(
          "scheduledatetime",
          "Scheduled Date & Time"
        ),
        render: (text) => <p>{this.cronToDate(text.data?.scheduleDateTime)}</p>,
      },

      {
        title: "No. of Recipients",
        key: "recipients",
        align: "center",
        render: (text) => <p>{text.data?.recipients.length}</p>,
      },
    ];

    const expandedRowRender = (text) => {
      return (
        <Descriptions
          style={{ padding: "0 0 0 40px" }}
          layout="horizontal"
          bordered
          labelStyle={{ width: "26.5%" }}
          contentStyle={{ width: "73%", backgroundColor: "white" }}
        >
          <Descriptions.Item label="Message">
            <div
              className=""
              type="primary"
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              <Paragraph
                ellipsis={
                  this.state.moreMessage
                    ? {
                        rows: 2,
                        expandable: true,
                        symbol: "more",
                      }
                    : {
                        rows: 1,
                        expandable: true,
                        symbol: "more",
                      }
                }
              >
                {text.data?.broadcastMessage === ""
                  ? "No Message"
                  : text.data?.broadcastMessage}
              </Paragraph>
            </div>
          </Descriptions.Item>
          <br />
          <br />
          <Descriptions.Item label="Attachments">
            {text.data?.pathName !== null &&
            text.data?.pathName !== undefined ? (
              <Row className="title-row">
                <Col>{text.data?.fileName}</Col>
                <Col xs={4} sm={4} md={4} lg={1} xl={1}></Col>
                <Col>
                  <button
                    type="button"
                    className="rounded btn-success"
                    onClick={() => this.downloadImage(text?.data.pathName)}
                  >
                    <DownloadOutlined />
                  </button>
                </Col>
              </Row>
            ) : (
              <div>No Attachments</div>
            )}
          </Descriptions.Item>
          <br />
          <br />
          <Descriptions.Item label="Recipients ">
            <div
              className=""
              type="primary"
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                textOverflow: "ellipsis",
              }}
            >
              {text.data?.recipients.map((recipient) => {
                return (
                  <div>
                    {recipient.name}
                    <br />
                  </div>
                );
              })}
            </div>
          </Descriptions.Item>
          <br />
          <br />
        </Descriptions>
      );
    };
    let upcomingBroadcasts = [];
    this.props.schedules.map((broadcast) => {
      if (broadcast.status === "scheduled") {
        let date = this.cronToDate(broadcast.scheduledatetime);
        broadcast.date = date;
        upcomingBroadcasts.push(broadcast);
      }
    });
    let completedBroadcasts = [];
    this.props.schedules.map((broadcast) => {
      if (broadcast.status === "broadcasted") {
        let date = this.cronToDate(broadcast.scheduledatetime);
        broadcast.date = date;
        completedBroadcasts.push(broadcast);
      }
    });
    return (
      <div className="container dashboard statistics-card">
        {this.state.editSchedule ? (
          <Navigate
            to="/scheduleBroadcast"
            replace={true}
            state={this.state.editableData}
          />
        ) : (
          ""
        )}
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={22} xl={22}>
            <h2 className="page-title">List Of Scheduled Broadcasts</h2>
            <BreadCrumbs location={this.props.location} />
          </Col>

          <Col xs={24} sm={24} md={24} lg={2} xl={2}>
            <Link to="/">
              <Button
                className="btn-admin btn-subCategory"
                type="primary"
                style={{ borderRadius: "5px" }}
              >
                Back
              </Button>
            </Link>
          </Col>
        </Row>

        <div className="mt-4">
          <div className="card">
            <div className="card-body p-5">
              <Tabs
                defaultActiveKey="1"
                onChange={this.onChange}
                items={[
                  {
                    label: `Upcoming Broadcasts`,
                    key: "2",
                    children: (
                      <Table
                        rowKey="_id"
                        align="left"
                        expandable={{
                          expandedRowRender,
                        }}
                        loading={upcomingBroadcasts === null}
                        columns={columns}
                        dataSource={upcomingBroadcasts}
                      />
                    ),
                  },
                  {
                    label: `Completed Broadcasts`,
                    key: "3",
                    children: (
                      <Table
                        expandable={{
                          expandedRowRender,
                          defaultExpandedRowKeys: ["0"],
                        }}
                        loading={completedBroadcasts === null}
                        rowKey="_id"
                        align="left"
                        columns={completedColumns}
                        dataSource={completedBroadcasts}
                      />
                    ),
                  },
                ]}
              />
              <div className="p-5" style={{ marginLeft: "34%" }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ScheduledEventsComponent.propTypes = {
  location: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  errors: state.errors,
  businessGroups: state.users.businessGroups,
  businessUnits: state.users.businessUnits,
  locations: state.users.locations,
  users: state.users.users,
  events: state.events.events,
  groups: state.users.groups,
  schedules: state.events.schedules,
});
export default connect(mapStateToProps, {
  getBusinessGroups,
  getBusinessUnits,
  getLocations,
  getAllUsers,
  deleteSchedule,
  getAllGroups,
  getAllEvents,
  getAllSchedules,
})(ScheduledEventsComponent);
