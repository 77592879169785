import { Col, Row } from "antd";
import React, { Component } from "react";
import { Form, message, Input, Button } from "antd";
import {
  IdcardOutlined,
  MailOutlined,
  MobileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createUser } from "../../actions/userAction";
import axios from "axios";
import BreadCrumbs from "../BreadCrumbs";
import { Auth } from "aws-amplify";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      mobileNumber: "",
      empId: "",
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  onFinish = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let user = {
      name: this.state.name,
      email: this.state.email,
      mobileNumber: "91" + this.state.mobileNumber,
      empId: this.state.empId,
    };

    let resp = await axios.post(
      `https://km9q24vq5c.execute-api.ap-south-1.amazonaws.com/dev/createuser`,
      user,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    message
      .success(`Registration Successfull !`)
      .then(
        this.setState({ name: "", email: "", mobileNumber: "", empId: "" })
      );
  };
  render() {
    return (
      <div className="container dashboard statistics-card registration">
        <Row className="title-row">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div>
              <h2 className="page-title">Register User</h2>
              <BreadCrumbs location={this.props.location} />
            </div>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="list-accounts mt-4">
              <div className="card ">
                <div
                  className="card-body "
                  style={{
                    padding: "60px",
                  }}
                >
                  <Form
                    name="add-users"
                    className="add-users"
                    onFinish={this.onFinish}
                    initialValues={{ remember: true }}
                  >
                    <Form.Item
                      name="mobileNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your Mobile Number!",
                        },
                      ]}
                    >
                      <Input
                        name="mobileNumber"
                        value={this.state.mobileNumber}
                        onChange={this.onChange}
                        prefix={<MobileOutlined />}
                        placeholder="Mobile Number"
                        type="number"
                      />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please Enter Your E-mail!",
                        },
                      ]}
                    >
                      <Input
                        name="email"
                        value={this.state.email}
                        onChange={this.onChange}
                        prefix={<MailOutlined />}
                        placeholder="Email"
                      />
                    </Form.Item>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your Name!",
                        },
                      ]}
                    >
                      <Input
                        name="name"
                        value={this.state.name}
                        onChange={this.onChange}
                        prefix={<UserOutlined />}
                        placeholder="Name"
                      />
                    </Form.Item>
                    <Form.Item
                      name="empId"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your Employee Id!",
                        },
                      ]}
                    >
                      <Input
                        name="empId"
                        value={this.state.empId}
                        onChange={this.onChange}
                        prefix={<IdcardOutlined />}
                        placeholder="Employee ID"
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        style={{ width: "100%" }}
                      >
                        Register
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
Register.propTypes = {
  createUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default connect(null, { createUser })(Register);
