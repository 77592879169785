import React, { Component } from "react";
import {
  UsergroupAddOutlined,
  DownloadOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { Row, Col, Tooltip } from "antd";
import { connect } from "react-redux";
import { getAllEvents } from "../../../actions/eventActions";
import EventPieChart from "./EventPieChart";
import BroadcastPieChart from "./BroadcastPieChart";
import FeedbackResultPieChart from "./FeedbackResultReportPieChart";
import FeedbackIndividualReportPieChart from "./FeedbackIndividualReportPieChart";
import BroadcastGraph from "./BroadcastGraph";
import { CCol, CRow } from "@coreui/react";
import BreadCrumbs from "../../BreadCrumbs";
import "../../../App.css";
import { Button } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: null,
      sent: 0,
      failed: 0,
      delivered: 0,
      totalBroadcast: 0,
      message: "",
    };
  }
  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    //Fetching Cards Data
    fetch(
      "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/analytics",
      { headers: { Authorization: "Bearer " + accessToken } }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ apiData: data });
      });
  }

  render() {
    const headers = [
      { label: "TotalActiveUsers", key: "TotalActiveUsers" },
      { label: "totalScheduleBroadcasts", key: "totalScheduleBroadcasts" },
      { label: "totalSentMessages", key: "totalSentMessages" },
      { label: "totalFailedMessages", key: "totalFailedMessages" },
      { label: "totalBroadcasts", key: "totalBroadcasts" },
    ];

    const data = [
      {
        TotalActiveUsers: this.state.apiData?.body.activeUsers.activeUsers,
        totalScheduleBroadcasts:
          this.state.apiData?.body.schedules.totalSchedules,
        totalBroadcasts: this.state.apiData?.body.broadcasts.totalBroadcasts,
        totalSentMessages: this.state.apiData?.body.broadcasts.totalSent,
        totalFailedMessages: this.state.apiData?.body.broadcasts.totalFailed,
      },
    ];

    return (
      <>
        <CRow>
          <div>
            <h2 className="page-title">Analytics</h2>
            <BreadCrumbs location={this.props.location} />
            <CSVLink
              data={data}
              headers={headers}
              filename={"report.csv"}
              className="float-end btn-dwn"
            >
              <Tooltip title="Export to csv">
                <div className="download-btn float-end">
                  <Button size="large" type="text">
                    <DownloadOutlined />
                  </Button>
                </div>
              </Tooltip>
            </CSVLink>
          </div>
        </CRow>

        <div>
          <div className="dashboard-new">
            <div>
              <CRow>
                <CCol xs={12} sm={6} md={6} lg={6} xl={4}>
                  <div className="card-name-and-style6" color="primary">
                    <>
                      <CRow>
                        <CCol>
                          <h4 style={{ color: "white" }}>
                            Current Active Users
                          </h4>
                          <h1 style={{ color: "white" }}>
                            <b>
                              {this.state.apiData?.body.activeUsers.activeUsers}
                            </b>
                          </h1>
                        </CCol>
                        <CCol className="custom-flex">
                          <div>
                            <UsergroupAddOutlined
                              style={{
                                float: "right",
                                fontSize: "300%",
                                color: "white",
                              }}
                            />
                          </div>
                        </CCol>
                      </CRow>
                    </>
                  </div>
                </CCol>
                <CCol xs={12} sm={6} md={12} lg={4} xl={4}>
                  <div className="card-name-and-style1" color="primary">
                    <>
                      <CRow>
                        <CCol>
                          <h4 style={{ color: "white" }}>
                            Scheduled Broadcasts
                          </h4>
                          <h1 style={{ color: "white" }}>
                            <b>
                              {
                                this.state.apiData?.body.schedules
                                  .totalSchedules
                              }
                            </b>
                          </h1>
                        </CCol>
                        <CCol className="custom-flex">
                          <div>
                            <FieldTimeOutlined
                              style={{
                                float: "right",
                                fontSize: "300%",
                                color: "white",
                              }}
                            />
                          </div>
                        </CCol>
                      </CRow>
                    </>
                  </div>
                </CCol>
                <CCol xs={12} sm={6} md={12} lg={4} xl={4}>
                  <div className="card-name-and-style5" color="primary">
                    <>
                      <CRow style={{ margin: "-7px 0 0 0" }}>
                        <CCol>
                          <h4 style={{ color: "white" }}>Total Broadcasts </h4>
                          <h2 style={{ color: "white" }}>
                            {this.state.apiData !== null
                              ? this.state.apiData?.body.broadcasts
                                  .totalBroadcasts
                              : 0}
                          </h2>
                        </CCol>
                        <CCol className="my-col">
                          <h4 style={{ color: "white" }}>Sent</h4>
                          <h2
                            style={{ color: "#3f8600", margin: "24px 0 0 0" }}
                          >
                            {this.state.apiData !== null
                              ? this.state.apiData?.body.broadcasts.totalSent
                              : 0}
                          </h2>
                        </CCol>
                        <CCol className="my-col">
                          <h4 style={{ color: "white" }}>Failed</h4>
                          <h2
                            style={{ color: "#cf1322", margin: "24px 0 0 0" }}
                          >
                            {this.state.apiData !== null
                              ? this.state.apiData?.body.broadcasts.totalFailed
                              : 0}
                          </h2>
                        </CCol>
                      </CRow>
                    </>
                  </div>
                </CCol>
              </CRow>
            </div>
          </div>

          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="count-cards mt-5">
                <div className="card">
                  <FeedbackResultPieChart />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="count-cards mt-5">
                <div className="card">
                  <FeedbackIndividualReportPieChart />
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="count-cards mt-3">
                <div className="card" style={{ height: "480px" }}>
                  <EventPieChart />;
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="count-cards mt-3">
                <div className="card" style={{ height: "480px" }}>
                  <BroadcastPieChart />
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div className="count-cards mt-5">
                <div className="card" style={{ height: "480px" }}>
                  <BroadcastGraph />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  dashboardData: state.users.dashboardData,
  events: state.events.events,
});
export default connect(mapStateToProps, {
  getAllEvents,
})(Analytics);
