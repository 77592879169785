import React, { Component } from "react";
import { Button, Table, Badge, Modal, Tabs, Tag, Select } from "antd";
import { getAllQueriesByBroadcastId } from "../../../actions/eventActions";
import { getAllGroups, deleteUserFromGroup } from "../../../actions/userAction";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { sendReplyToQuery } from "../../../actions/eventActions";
import Pusher from "pusher-js";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { Option } from "antd/lib/mentions";
import axios from "axios";

class Query extends Component {
  constructor(props) {
    super(props);
    this.state = {
      replyQuery: "",
      name: "",
      sendReplyLoader: false,
      queryLoader: true,
      allQueries: [],
      broadcastId: null,
      sendTemplateResource: false,
      inputFields: [],
      navigateToMessage: false,
      messageDetail: "",
      modelOpen: false,
      openQueryDialog: false,
      reply: "",
      replyOptions: [],
    };
  }

  //Query Dialog Visibility
  handleQueryDialogVisiblity = (isVisible) => {
    this.setState({
      openQueryDialog: isVisible,
    });
  };
  getAllReply = async () => {
    const url =
      "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/predefinedreplies";

    try {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const res = await axios.get(url, {
        headers: { Authorization: "Bearer " + accessToken },
      });
      console.log("response from query", res.data.body);
      this.setState({
        replyOptions: res.data.body,
      });
    } catch (error) {
      console.log("error from getAllReply", error);
    }
  };
  componentDidMount() {
    this.getAllReply();
  }

  //Get Broadcast
  getBroadcast = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.setState({ queryLoader: true });
    var pusher = new Pusher("20cf801b69f19fcea0a6", {
      cluster: "ap2",
    });
    const channelName = "whatsapp_employee_connect";
    var channel = pusher.subscribe(channelName);
    channel.bind("my_event", async function (data) {
      await this.props?.getAllQueriesByBroadcastId(
        data.data.broadcastId,
        accessToken
      );
    });
    await this.props.getAllQueriesByBroadcastId(
      this.props.broadcastId,
      accessToken
    );
    this.setState({ queryLoader: false });
  };
  handleReplyChange = (e) => {
    console.log("data from query----", e);
    this.setState({ reply: e });
  };

  //Send reply--------------
  sendReply = async (data) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let replyObject = {
      queryId: data._id,
      query: data.query,
      userName: data.userName,
      phoneNumber: data.phoneNumber,
      broadcastId: this.props.broadcastId,
      reply: this.state.reply,
      template: this.state.sendTemplateResource,
    };
    this.setState({ sendReplyLoader: true });
    setInterval(() => {
      this.setState({ sendReplyLoader: false });
    }, 5000);
    this.props.sendReplyToQuery(replyObject, accessToken);
  };

  //Send Template
  sendTemplate = (query) => {
    this.setState({
      replyQuery: "For any queries, kindly reach out to your HRPP",
      sendTemplateResource: !this.state.sendTemplateResource,
    });
  };

  //Change Query Reply
  changeQueryReply = (e) => {
    console.log("query value-----------", e.target.value);
    this.setState({ reply: e.target.value });
  };

  //Navigate to Message
  navigateToMessage = (text) => {
    this.setState({ navigateToMessage: true, messageDetail: text });
  };
  render() {
    const resolvedColumns = [
      {
        title: "Query",
        dataIndex: "query",
        key: "query",
      },
      {
        title: "Query By",
        dataIndex: "userName",
        key: "userName",
        width: "150px",
        render: (text) => <a>{text}</a>,
      },
      {
        title: "Reply",
        dataIndex: "reply",
        key: "reply",
        align: "center",
        width: "190px",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        render: (text) => (
          <Tag
            icon={<CheckCircleOutlined style={{ marginBottom: "4px" }} />}
            color="success"
          >
            Resolved
          </Tag>
        ),
      },
    ];
    const pendingColumns = [
      {
        title: "Query",
        dataIndex: "query",
        key: "query",
      },
      {
        title: "Query By",
        dataIndex: "userName",
        key: "userName",
        render: (text) => <a>{text}</a>,
      },
      Table.EXPAND_COLUMN,
      {
        dataIndex: "replyToQuery",
        key: "replyToQuery",
        width: "1px",
      },

      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        render: (text) => (
          <Tag
            icon={<ExclamationCircleOutlined style={{ marginBottom: "4px" }} />}
            color="warning"
          >
            {text}
          </Tag>
        ),
      },
    ];

    const items = [
      {
        key: "1",
        label: `Pending Queries`,
        children: (
          <div>
            <Table
              rowKey="_id"
              loading={
                this.state.queryLoader
                  ? {
                      indicator: (
                        <div>
                          <LoadingOutlined
                            style={{
                              fontSize: 24,
                            }}
                            spin
                          />
                        </div>
                      ),
                    }
                  : false
              }
              expandable={{
                expandedRowRender: (record) => (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        Replying to <b>{record.userName}</b>
                        <br />
                        Query- <b>{record.query}</b>
                      </div>
                      <div>
                        <Select
                          placeholder="Select reply"
                          style={{
                            width: 200,
                          }}
                          onChange={this.handleReplyChange}
                        >
                          {console.log(
                            "reply optionsa",
                            this.state.replyOptions
                          )}
                          {this.state.replyOptions.map((reply) => {
                            return (
                              <Option value={reply.reply}>{reply.reply}</Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>

                    <br />

                    <TextArea
                      id={record._id}
                      disabled={
                        this.state.sendTemplateResource ||
                        this.state.sendReplyLoader
                      }
                      name="textarea"
                      value={
                        this.state.reply
                          ? this.state.reply
                          : this.state.replyQuery
                      }
                      placeholder="Reply to Query"
                      onChange={this.changeQueryReply}
                    />
                    <div className="sendReply">
                      {!this.state.sendReplyLoader && (
                        <div>
                          <Button
                            type="primary"
                            onClick={() => {
                              this.sendReply(record);
                            }}
                          >
                            Send
                          </Button>
                          {/* &nbsp; &nbsp; OR */}
                          {/* <Button
                            type="text"
                            onClick={() => {
                              this.sendTemplate(record.query);
                            }}
                          >
                            <u>Send Template Response</u>
                          </Button> */}
                        </div>
                      )}
                      {this.state.sendReplyLoader && (
                        <LoadingOutlined
                          style={{
                            fontSize: 24,
                          }}
                          spin
                        />
                      )}
                    </div>
                  </div>
                ),
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <Button
                      onClick={(e) => {
                        onExpand(record, e);
                      }}
                    >
                      Reply To Query
                    </Button>
                  ) : (
                    <Button
                      onClick={(e) => {
                        onExpand(record, e);
                        this.handleQueryDialogVisiblity(true);
                      }}
                    >
                      Reply To Query
                    </Button>
                  ),
              }}
              columns={pendingColumns}
              dataSource={this.props.queries?.filter(
                (queries) => queries.status === "pending"
              )}
            />
          </div>
        ),
      },
      {
        key: "2",
        label: `Resolved Queries`,
        children: (
          <div>
            <Table
              loading={
                this.state.queryLoader
                  ? {
                      indicator: (
                        <div>
                          <LoadingOutlined
                            style={{
                              fontSize: 24,
                            }}
                            spin
                          />
                        </div>
                      ),
                    }
                  : false
              }
              columns={resolvedColumns}
              dataSource={this.props.queries?.filter(
                (queries) => queries.status === "resolved"
              )}
            />
          </div>
        ),
      },
    ];

    return (
      <div className="container dashboard statistics-card">
        <Modal
          title="Queries From users"
          style={{
            top: 20,
          }}
          width={800}
          open={this.state.modelOpen}
          onOk={() => this.setState({ modelOpen: false })}
          onCancel={() => this.setState({ modelOpen: false })}
        >
          <Tabs defaultActiveKey="1" items={items} />
        </Modal>

        <div className="query">
          <Button
            type="text"
            disabled={!this.props.queryIsExist}
            onClick={() => {
              this.getBroadcast();
              this.setState({
                modelOpen: true,
              });
            }}
          >
            {!this.props.queryIsExist ? (
              <h4>No Queries</h4>
            ) : (
              <Badge size="small" count={this.props.queryLength}>
                <QuestionCircleFilled />
              </Badge>
            )}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  groups: state.users.groups,
  sendBroadcastMsg: state.users.sendBroadCastMsgResponse,
  queries: state.events.queries,
});
export default connect(mapStateToProps, {
  sendReplyToQuery,
  getAllQueriesByBroadcastId,
  getAllGroups,
  deleteUserFromGroup,
})(Query);
