import { withAuthenticator } from "@aws-amplify/ui-react";
// import RegisterUser from "./RegisterUser";
import React from "react";
import "./SignIn.css";
import AuthHeader from "../layouts/AuthHeader";
import AdminDashboard from "../dashboard/AdminDashboard";

function SignIn(currentUser, location) {
  return <div className="header"></div>;
}

export default withAuthenticator(SignIn);
