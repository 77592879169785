import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../BreadCrumbs";
import { Row, Col, List, Avatar, Spin, Typography } from "antd";
import moment from "moment";
import ChatBody from "./ChatBody";
import axios from "axios";
import { Auth } from "aws-amplify";

export default function Conversation(props) {
  const [selectedUser, setSelectedUser] = useState();

  const [users, setUsers] = useState([]);
  const [userChat, setUserChat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      try {
        setLoading(true);
        const response = await axios.get(
          "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/conversation/users",
          { headers: { Authorization: "Bearer " + accessToken } }
        );
        console.log("Userssssssssssssss", response.data.body);
        setUsers(response.data.body);
        setSelectedUser(
          response.data.body.sort(function (a, b) {
            return new Date(b.timeStamp) - new Date(a.timeStamp);
          })[0].name
        );
        setLoading(false);
        getUserChat(response.data.body[0]._id);
      } catch (error) {
        console.log("Error===>", error);
      }
    };
    fetchData();
    setInterval(fetchData, 600000);
  }, []);

  const getUserChat = async (userId) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setChatLoading(true);
    try {
      const response = await axios.get(
        `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/conversation/chat?mobileNumber=${userId}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      console.log("User Chat Response", response.data);
      setUserChat(response.data);
      setChatLoading(false);
    } catch (error) {
      console.log("Error===>", error);
    }
  };

  function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      children: `${name?.split(" ")[0][0]}`,
    };
  }

  return (
    <div className="container dashboard statistics-card">
      <div className="row ">
        <div className="col-8">
          <h2 className="page-title">Conversation</h2>
          <BreadCrumbs location={props.location} />
        </div>
      </div>
      <div className="mt-4">
        <div className="card">
          <div className="card-body" style={{ height: "550px" }}>
            {loading ? (
              <div
                style={{
                  margin: "20px 0",
                  marginBottom: " 20px",
                  padding: "30px 50px",
                  textAlign: "center",
                  borderRadius: "4px",
                }}
              >
                <Spin />
              </div>
            ) : (
              <Row gutter={[16, 8]}>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div>
                    <div>
                      <h3
                        style={{
                          textAlign: "center",
                          background: "#5046e5",
                          color: "#fff",
                          padding: " 15px 0px",
                          margin: " 0px 0px 0 -5px",
                        }}
                      >
                        Users
                      </h3>
                    </div>
                    <div
                      style={{
                        height: 460,
                        overflow: "auto",
                        padding: "5px 16px",
                        borderRight: "1px solid rgba(140, 140, 140, 0.35)",
                      }}
                    >
                      <List
                        itemLayout="horizontal"
                        dataSource={users.sort(function (a, b) {
                          return new Date(b.timeStamp) - new Date(a.timeStamp);
                        })}
                        renderItem={(item, index) => (
                          <List.Item>
                            <List.Item.Meta
                              onClick={() => {
                                setSelectedUser(item.name);
                                getUserChat(item._id);
                              }}
                              style={{ cursor: "pointer" }}
                              avatar={
                                <Avatar
                                  {...stringAvatar(item.name)}
                                  style={{
                                    backgroundColor: stringToColor(item.name),
                                  }}
                                />
                              }
                              title={
                                <Typography.Text>{item.name}</Typography.Text>
                              }
                              description={
                                <Typography.Text>+{item._id}</Typography.Text>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>
                </Col>
                <Col
                  xl={18}
                  lg={18}
                  md={18}
                  sm={12}
                  xs={12}
                  style={{
                    margin: "0px 0px 0 -15px",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        background: "#5046e5",
                        color: "#fff",
                        padding: " 9px 0px",
                        margin: "0px 0px 0 -1px",
                      }}
                    >
                      <div style={{ padding: "0px 0px 0px 15px" }}>
                        <Avatar
                          {...stringAvatar(selectedUser)}
                          style={{
                            backgroundColor: stringToColor(selectedUser),
                          }}
                        />
                        &nbsp; {selectedUser}
                      </div>
                    </h3>
                  </div>
                  <div className="chat-app">
                    {chatLoading ? (
                      <div
                        style={{
                          margin: "20px 0",
                          padding: "30px 50px",
                          textAlign: "center",
                          borderRadius: "4px",
                        }}
                      >
                        <Spin />
                        <br />
                        <h6>Loading...</h6>
                      </div>
                    ) : (
                      <ChatBody messages={userChat} />
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
